import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import useSound from "use-sound";
import { SpinnerSVG } from "./SVG";
import PieSection from "./pieSection";
import ConfettiJSON from "../include/confetti.json";
import Lottie from "react-lottie-player";
import { createRef } from "react";

const lottieRef = createRef(null);
const successIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/crypto-wallet-Success-1.png";

const question = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/a.png";
const dice = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/f.png";
const dice2 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/g.png";
const dice3 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/h.png";
const dice4 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/j.png";
const key = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/k.png";
const key1 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/l.png";
const key2 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/m.png";
const key3 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/n.png";
const spinsfx = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/spin.mp3";
const win = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/win.mp3";
const press = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/press.mp3";
const tick = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/tick.wav";
const bonusWin = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/bonus.mp3";
const heartbeat = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/heartbeat.wav";

const Spinner = ({ spinState, sections, setAllowClose, setShowSuccess }) => {
  const pointerRef = useRef(null);
  const wheelRef = useRef(null);
  const [bonus, setBonus] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [spin, setSpin] = useState(false);
  const prevTick = useRef(null);

  // const rewardSections = createSections(sections);
  let newSec = sections?.filter((section) => (bonus ? section.spinnerLocation === 2 : section.spinnerLocation === 1));
  if (!bonus) {
    newSec.push({
      ...sections[0],
      angle: 0,
      image: question,
      value: "Bonus",
      color: "#171717",
      id: "1",
    });
    newSec.push({
      ...sections[3],
      angle: 180,
      image: question,
      value: "Bonus",
      color: "#171717",
      id: "2",
    });
  }

  const defaultColors = useMemo(
    () => sections?.reduce((obj, section) => ({ ...obj, [section?.position]: section?.color }), {}),
    [],
  );
  const [positionColor, setPositionColor] = useState(() =>
    sections?.reduce((obj, section) => ({ ...obj, [section?.position]: section?.color }), {}),
  );

  const baseColorLight = "#1e1e1e";
  const baseColorDark = "#171717";
  const highlightColor = "#B56200";
  const selectedColor = "#04C100";

  const [playPress] = useSound(press, { volume: 0.5 });
  const [playSpin] = useSound(spinsfx, { volume: 0.5 });
  const [playWin] = useSound(win, { volume: 0.5 });
  const [playTick] = useSound(tick, { volume: 0.5 });
  const [playBonus] = useSound(bonusWin, { volume: 0.5 });
  const [playHeartbeat] = useSound(heartbeat, { volume: 0.5 });

  const handleSpin = () => {
    if (!spin) {
      setSpin(true);
      playPress();
      playSpin();
    }
  };

  const findCurrentSection = useCallback((rotation) => {
    const currentRotation = ((rotation % 360) + 360) % 360; // Normalize rotation
    for (const section of sections) {
      let startAngle = section.angle - 30;
      let endAngle = section.angle + 30;

      // Special case for the top-center section crossing the 0-degree boundary
      if (section.angle === 0) {
        if (currentRotation >= 330 || currentRotation < 30) {
          return [section.position, section.id];
        }
      } else {
        if (startAngle < 0) startAngle += 360;
        if (endAngle >= 360) endAngle -= 360;

        if (currentRotation >= startAngle && currentRotation < endAngle) {
          return [section.position, section.id, section.type, section.value];
        }
      }
    }
    return null;
  }, []);

  const updateSectionColors = useCallback((rotation, finalColor = null) => {
    const newSection = findCurrentSection(rotation)[0];

    if (newSection !== currentSection) {
      setCurrentSection(newSection);

      sections.forEach((section) => {
        const color = section.position === newSection ? finalColor || highlightColor : section.color;

        document.documentElement.style.setProperty(`--${section.position}`, color);
      });
    }
  }, []);

  const checkAndPlayTick = useCallback(
    (currentRotation) => {
      const angles = [30, 90, 150, 210, 270, 330];
      const tolerance = 5; // Tolerance for floating-point precision
      const roundedRotation = Math.floor(currentRotation * -1);
      if (angles.some((angle) => Math.abs(roundedRotation - angle) < tolerance && prevTick.current !== angle)) {
        updateSectionColors(currentRotation - 15);
        playTick();
        prevTick.current = angles.find((angle) => Math.abs(roundedRotation - angle) < tolerance);
      }
    },
    [playTick, updateSectionColors],
  );

  const duration = bonus ? 5.0 : 5.25;
  const backout = bonus ? 0.0 : 0.6;

  useGSAP(() => {
    //useGSAP is similar to useEffect but it is used for GSAP animations
    // this triggers animations for the regular spin and bonus spin
    if (spin || bonus) {
      let delayComplete = false;

      const rotationArray = [1080, 1020, 1320, 1260, 1200, 1140];
      const index = spinState?.spinnerLocation === 2 && !bonus ? 0 : spinState?.angle / 60;
      const randomRotation = rotationArray[index];

      const randomBackout = Math.random() * 0.5 + backout;
      if (!bonus) {
        gsap.to(wheelRef.current, {
          rotate: randomRotation,
          duration: duration,
          ease: `back.out(${randomBackout})`,
        });
      }

      gsap.to(pointerRef.current, {
        rotation: -randomRotation,
        duration: duration,
        ease: `back.out(${randomBackout})`,
        onStart: () => {
          setTimeout(() => {
            delayComplete = true;
          }, 200);
          if (randomBackout >= 0.75) {
            setTimeout(() => {
              playTick();
              updateSectionColors((randomRotation % 360) * -1);
            }, duration * 1000 * 0.76);
          }
        },
        onUpdate: () => {
          const currentRotation = gsap.getProperty(pointerRef.current, "rotation") % 360;

          if (delayComplete) {
            checkAndPlayTick(currentRotation);
          }
        },
        onComplete: () => {
          const currentRotation = gsap.getProperty(pointerRef.current, "rotation") % 360;
          updateSectionColors(currentRotation, selectedColor);
          if (spinState?.spinnerLocation === 2 && !bonus) {
            gsap.to(pointerRef.current, {
              rotation: 0,
              duration: 0.0,

              delay: 1,
              onComplete: () => {
                setBonus(true);
                setTimeout(() => {
                  playBonus();
                }, 1950);
                setTimeout(() => {
                  setShowSuccess(true);
                  setAllowClose(true);
                }, 6000);
                playHeartbeat();
              },
            });
          } else {
            setTimeout(() => {
              setShowSuccess(true);
            }, 2000);
          }
          setAllowClose(true);
          playWin();
        },
      });
    }
  }, [spin, bonus]);

  useEffect(() => {
    if (spinState) {
      handleSpin();
    }
  }, [spinState]);

  useEffect(() => {
    if (bonus) setAllowClose(false);
  }, [bonus]);

  return (
    <div className="spinnerdiv" ref={wheelRef}>
      <SpinnerSVG positionColor={positionColor} />
      <div className="pointerdiv" ref={pointerRef}>
        <div className="arrow"></div>
        <div className="pointerbase"></div>
      </div>

      {newSec?.map((section, index) => (
        <PieSection
          key={section.position}
          position={section.position}
          text={section?.value}
          imageSrc={
            !bonus && (section.angle === 0 || section.angle === 180)
              ? section?.image
              : process.env.REACT_APP_CDNURL + section?.image
          } // You can replace this with corresponding image
          color={section.color}
        />
      ))}
    </div>
  );
};
export default Spinner;
