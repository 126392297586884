import _ from "lodash";
import mixpanel from "mixpanel-browser";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  checkEmail,
  checkUsername,
  contestEntry,
  getUser,
  loadBountyBoxes,
  loadProfile,
  loginUser,
  registerUser,
  resendOTP,
  resetPassword,
  toggleDiscordModalRedux,
  toggleHowToPlayRedux,
  toggleHTPRedux,
  togglePopupRedux,
  updateImage,
  verifyOTP,
} from "store/user";
import { getCookie } from "utils/cookiesUtilities";
import { setToken } from "utils/localStorageServices";
import { loadJS } from "utils/misc";
import { withRouter } from "utils/withRouter";

//  Components
import AlertError from "common/alert/alertError";
import DateDropDown from "common/dateDropDown";
import moment from "moment";
import { toast } from "react-toastify";
import { dateAsUtc } from "utils/dateConversion";
import { verisoulSessionId } from "utils/verisoulUtils";
import ErrorPopup from "../../errorPopup";
import { prepareData } from "../../gtmDatalayer";
import LoaderImg from "../../loaderImag";

//  Images
const logo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/bgNewLogo.svg";
const closeIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/close-icon.svg";
const mailIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/mail-ad-icon.svg";
const usernameIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/username.svg";
const passwordIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/password.svg";
const eyeIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/eye.svg";
const loader = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/loader-grey.svg";
const eyeOffIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/eye-off.svg";
const creditImage = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/credit-image-20.jpg";
const creditIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/credit-icon.svg";

const HowItWorks = (props) => {
  const {
    showModal,
    toggleModal,
    getUser,
    loginUser,
    registerUser,
    verifyOTP,
    loadProfile,
    updateImage,
    checkEmail,
    checkUsername,
    toggleHowToPlayRedux,
    toggleHTPRedux,
    resendOTP,
    resetPassword,
    toggleDiscordModalRedux,
    onboardingType = "login",
    loadBountyBoxes,
    setTab,
    walletDetails,
  } = props;
  const aRef = useRef();
  const bRef = useRef();
  const cRef = useRef();
  const dRef = useRef();
  const eRef = useRef();

  //  Component States
  const [data, setData] = useState({
    email: "",
    password: "",
    cPassword: "",
    userName: "",
    verificationCode1: "",
    verificationCode2: "",
    verificationCode3: "",
    verificationCode4: "",
    date: {},
    term: false,
  });

  const handleCheck = (e) => {
    const { name, checked } = e;
    const newData = structuredClone(data);
    const newError = structuredClone(errors);
    newData[name] = checked;
    if (checked) delete newError[name];
    setData(newData);

    setErrors(newError);
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    const newErr = type === "login" ? {} : structuredClone(errors);
    const otp = structuredClone(data);
    if (type === "resetPassword2" && name === "password") delete newErr.cPassword;
    if (
      name.includes("verificationCode") &&
      ((value !== "" && otp[name] !== "") || (value !== "" && isNaN(parseInt(value))))
    )
      return;
    delete newErr[name];

    otp[name] = value;
    if (otp[name] !== "") {
      setData(otp);
      if (name === "verificationCode1") bRef.current.focus();
      if (name === "verificationCode2") cRef.current.focus();
      if (name === "verificationCode3") dRef.current.focus();
      if (name === "verificationCode4") eRef.current.focus();
    } else {
      setData(otp);
    }
    setErrors(newErr);
  };
  const onChange2 = async (e) => {
    const { name, value } = e.target;
    const newErr = structuredClone(errors);
    const otp = structuredClone(data);
    if ((value !== "" && otp[name] !== "") || (value !== "" && isNaN(parseInt(value)))) return;
    delete newErr[name];

    let multiVal = value?.split("");
    if (value === "") {
      otp[name] = "";
      setData(otp);
    } else if (name === "verificationCode1" && value?.length > 1) {
      let multiVal = value?.split("");
      otp.verificationCode1 = multiVal[0];
      otp.verificationCode2 = multiVal[1];
      otp.verificationCode3 = multiVal[2];
      otp.verificationCode4 = multiVal[3];
      eRef.current.focus();
      setData(otp);
    } else if (value?.length > 1) {
      otp[name] = multiVal[0];
    } else if (value?.length === 1) {
      otp[name] = value;
      setData(otp);
      if (name === "verificationCode1") bRef.current.focus();
      if (name === "verificationCode2") cRef.current.focus();
      if (name === "verificationCode3") dRef.current.focus();
      if (name === "verificationCode4") eRef.current.focus();
    } else {
      setData(otp);
    }
    setErrors(newErr);
  };
  const onChangeDate = (e) => {
    const { name, value } = e.target;
    const newErr = structuredClone(errors);
    const newData = structuredClone(data);
    delete newErr[name];
    newData[name] = value;

    if (value?.days && !isNaN(value?.months) && value?.years && moment().diff(moment(value), "years") < 18)
      newErr[name] = "You must be 18 or older to create an account";
    setData(newData);
    setErrors(newErr);
  };
  const [errors, setErrors] = useState({});
  const [type, setType] = useState(onboardingType ?? "signup");
  const [type2, setType2] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingUsername, setLoadingUsername] = useState(false);
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidUserName, setIsValidUserName] = useState(false);
  const [isVerificationComplete, setIsVerificationComplete] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [finishSetup, setFinishSetup] = useState(false);

  //  All useEffects for data re-rendering
  useEffect(() => {
    if (showModal) {
      if (process.env.REACT_APP_MIXPANEL === "1") {
        if (type === "signup") mixpanel.track("View email registration form");
        if (type === "login") mixpanel.track("View login form ");
      }
      const { profile } = getUser;
      const { userProfile } = profile;
      if (!userProfile || !userProfile.walletAddress) {
        setType(onboardingType);
      }
      if (!_.isEmpty(userProfile)) {
        toggleModal();
      } else if (userProfile?.walletAddress) toggleModal();
    } else {
      clearData();
    }
  }, [showModal]);

  useEffect(() => {
    setErrors({});
    setIsValidEmail(false);
    setIsValidUserName(false);
    if (process.env.REACT_APP_MIXPANEL === "1" && showModal) {
      if (type === "signup") mixpanel.track("View registration Form");
      if (type === "verify") mixpanel.track("View verification code prompt");
    }
    if (process.env.REACT_APP_GA === "1" && showModal) {
      if (type === "verify") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
          prepareData({
            event: "View verification code prompt",
          }),
        );
      }
    }
  }, [type]);

  useEffect(() => {
    if (data.verificationCode1 && data.verificationCode2 && data.verificationCode3 && data.verificationCode4) {
      delete errors.verificationCode;
    }
  }, [data.verificationCode1, data.verificationCode2, data.verificationCode3, data.verificationCode4]);

  //  Switch case method to get the title of the flyer
  const getTitle = () => {
    switch (type) {
      case "signup":
        return finishSetup ? "Finish Setup" : "Create Account";
      case "resetPassword":
        return "Reset Password";
      case "resetPassword2":
        return "Reset Password";
      case "verify":
        return isVerificationComplete ? "Verification Complete" : "Verify Email Address";
      default:
        return "Login Now";
    }
  };

  //  Switch case method to get the sub-title of the flyer
  const getSubTitle = () => {
    switch (type) {
      case "signup":
        return "Create an account in just a few clicks to reserve your place in the metaverse.  ";
      case "resetPassword":
        return "Enter your user account's verified email address and we will send you a four digit verification code.";
      case "resetPassword2":
        return "Enter your new password";
      case "verify":
        return type2 === "resetPassword"
          ? "Enter the 4 digit code that was delivered to your email to finish resetting your password. This code will expire in 15 minutes."
          : isVerificationComplete
          ? "Play your first few games on us!"
          : "Enter the 4 digit code that was delivered to your email to finish setting up your account. This code will expire in 15 minutes.";
      default:
        return "Enter your username (or email address) & password to login.";
    }
  };

  //  Method to clear all the states
  const clearData = () => {
    setData({
      term: false,
      email: "",
      password: "",
      userName: "",
      verificationCode1: "",
      verificationCode2: "",
      verificationCode3: "",
      verificationCode4: "",
      date: {},
    });
    setIsVerificationComplete(false);
    setErrors({});
    setType("signup");
    setShowPass(false);
    setLoading(false);
    setLoadingEmail(false);
    setLoadingUsername(false);
    setShowError(false);
    setIsValidEmail(false);
    setIsValidUserName(false);
    setType2("");
    setShowPass2(false);
    setOtpSent(false);
  };

  //  On Clicking on submit buttons data validation is done and api is called
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (loading || loadingUsername || loadingEmail) return;
    let newErrors = structuredClone(errors);
    var mailformat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //  Validations of the data
    if (type === "verify") {
      if (!data.verificationCode1 || !data.verificationCode2 || !data.verificationCode3 || !data.verificationCode4) {
        newErrors.verificationCode = "Please enter the 4 digit code sent to your email address";
      }
    } else if (type === "resetPassword") {
      if (_.isEmpty(data.email)) {
        newErrors.email = "Email is required";
      }
      if (data.email && !mailformat.test(data.email)) {
        newErrors.email = "Email is invalid";
      }
    } else if (type === "resetPassword2") {
      if (_.isEmpty(data.password)) {
        newErrors.password = "Password is required";
      }
      if (data.password && data.password.length < 6) newErrors.password = "Password must be at least 6 characters";
      if (data.cPassword !== data.password) {
        newErrors.cPassword = "Passwords do not match";
      }
    } else {
      if (_.isEmpty(data.userName))
        newErrors.userName = type === "login" ? "Username or email is required" : "Username is required";

      if (type === "signup") {
        if (_.isEmpty(data.email)) {
          newErrors.email = "Email is required";
        }
        if (data.email && !mailformat.test(data.email)) {
          newErrors.email = "Email is invalid";
        }

        if (finishSetup) {
          if (!data.term) {
            newErrors.term = "Please agree to the Burn Ghost Terms of Service";
          }
          if (!data?.date?.days || isNaN(data?.date?.months) || !data?.date?.years)
            newErrors.date = "Date of birth is required";
          else if (moment().diff(moment(data?.date), "years") < 18)
            newErrors.date = "You must be 18 or older to create an account";
        }
      }
      if (_.isEmpty(data.password) && _.isEmpty(walletDetails)) {
        newErrors.password = "Password is required";
      }
      if (type === "signup" && data.password && data.password.length < 6)
        newErrors.password = "Password must be at least 6 characters";
    }

    if (!_.isEmpty(newErrors)) {
      if (type === "signup") {
        if (process.env.REACT_APP_GA === "1") {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(
            prepareData({
              event: "Submit registration form_failure",
            }),
          );
        }
        if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("Submit registration form_failure");
      }
      return setErrors(newErrors);
    }
    setLoading(true);

    const { email, password, userName } = data;
    if (type === "login") {
      const sessionId = await verisoulSessionId();
      loginUser({ userName: userName.toLowerCase(), password, sessionId }, (loginRes) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
          prepareData({
            event: "login", // fixed value
          }),
        );
        if (loginRes.status === 200) {
          toggleModal();

          localStorage.setItem("userProfile", JSON.stringify(loginRes.data.userProfile));

          if (process.env.REACT_APP_MIXPANEL === "1") {
            mixpanel?.track("Login", { Type: "email" });
            mixpanel.identify(loginRes?.data?.userProfile?.id);
          }
          setToken(false, loginRes.data.jwtToken);
          loadProfile((res) => {
            if (res?.data?.connectedWallet?.walletAddress) {
              loadBountyBoxes();
            }
          });
          window.innerWidth > 1280.98 && toggleDiscordModalRedux(true);
        } else {
          newErrors.password = loginRes.data.message;
          setErrors(newErrors);
        }
        setLoading(false);
      });
    } else if (type === "signup") {
      if (finishSetup) {
        if (process.env.REACT_APP_MIXPANEL === "1") {
          mixpanel?.track("Submit registration form_successful");
        }
        if (process.env.REACT_APP_GA === "1") {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(
            prepareData({
              event: "Submit email registration form_successful",
            }),
          );
        }
        const sessionId = await verisoulSessionId();
        const referralCode = localStorage.getItem("referralCode");
        const context = {
          hutk: getCookie("hubspotutk"),
          pageUri: window?.location?.host,
          pageName: document?.title,
        };
        const payload = {
          email,
          userName: userName.toLocaleLowerCase(),
          dateOfBirth: parseInt(dateAsUtc(data?.date, "x")),
          sessionId,
          context,
          ...walletDetails,
        };
        if (password) {
          payload.password = password;
        }
        if (referralCode) {
          payload.referralCode = referralCode;
        }
        registerUser(payload, (signupRes) => {
          if (signupRes.status === 200) {
            localStorage.setItem("signup-token", signupRes.data.token);
            setType("verify");
          } else {
            toast(
              <AlertError message="You've surpassed the allowable number of requests. Please attempt again later." />,
            );
            toggleModal();
            newErrors.date = signupRes.data.message;
            setErrors(newErrors);
          }
          setFinishSetup(false);
          setLoading(false);
        });
      } else {
        setFinishSetup(true);
        setLoading(false);
      }
    } else if (type === "verify" || type === "resetPassword2") {
      const otp = data.verificationCode1 + data.verificationCode2 + data.verificationCode3 + data.verificationCode4;
      var payLoad = {
        otp: parseInt(otp),
        token: localStorage.getItem("signup-token"),
        type:
          type === "verify" && type2 === "resetPassword"
            ? "verifyOtp"
            : type === "resetPassword2"
            ? "resetPassword"
            : type2 === "resetPassword"
            ? "verifyOtp"
            : "registration",
      };
      if (type === "resetPassword2") payLoad.password = password;
      if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("Submit verification code_successful");
      if (process.env.REACT_APP_GA === "1") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
          prepareData({
            event: "Submit verification code_successful",
          }),
        );
      }
      verifyOTP(payLoad, (verifyRes) => {
        if (verifyRes.status === 200 && type2 !== "resetPassword") {
          // setIsVerificationComplete(true);
          setToken(false, verifyRes.data.jwtToken);
          localStorage.setItem("userProfile", JSON.stringify(verifyRes.data.userProfile));

          if (process.env.REACT_APP_GA === "1") {
            loadJS("https://track.playcradle.com/static/burnghost_tracking_min.js", true);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(
              prepareData({
                event: "create account",
              }),
            );
            window.dataLayer.push(
              prepareData({
                event: "Create account_email",
              }),
            );
          }
          if (process.env.REACT_APP_MIXPANEL === "1") {
            mixpanel.identify(verifyRes?.data?.userProfile?.id);
            // Track an event. It can be anything, but in this example, we're tracking a Signed Up event.
            // Include a property about the signup, like the Signup Type
            mixpanel?.track("Create Account", { Type: "email" });
            mixpanel?.track("Create account_email");
          }

          updateImage(
            {
              image: `Avatars/${Math.floor(Math.random() * 140) + 1}.png`,
            },
            () => {
              toggleHTPRedux(true);
              loadProfile((loginRes) => {
                if (loginRes.status === 200) {
                  localStorage.setItem("userProfile", JSON.stringify(loginRes.data.userProfile));
                }
              });
              if (verifyRes.data?.userProfile?.arcadeCoins > 0 || verifyRes.data?.userProfile?.lootBonusCoins > 0)
                setTab(5);
              else {
                // togglePopupRedux(true);
                toggleHowToPlayRedux(true);
                toggleModal();
              }
              window.innerWidth > 1280.98 && toggleDiscordModalRedux(true);
              // toggleModal(e);
              setLoading(false);
            },
          );
        } else if (type === "resetPassword2" && verifyRes.status === 200) {
          clearData();
          setLoading(false);
          setType("login");
        } else if (verifyRes.status === 200) {
          setType("resetPassword2");
          localStorage.setItem("reset-otp", otp);
          setLoading(false);
        } else {
          if (type === "resetPassword2") newErrors.cPassword = verifyRes.data.message;
          else newErrors.verificationCode = verifyRes.data.message;
          if (process.env.REACT_APP_MIXPANEL === "1") {
            mixpanel?.track("Submit verification code_invalid code");
            mixpanel?.track("create account_email_failure");
            mixpanel?.track("create account_failure");
          }
          if (process.env.REACT_APP_GA === "1") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(
              prepareData({
                event: "Create Account_failure",
              }),
            );
            window.dataLayer.push(
              prepareData({
                event: "create account_email_failure",
              }),
            );
            window.dataLayer.push(
              prepareData({
                event: "Submit verification code_error",
              }),
            );
          }

          setErrors(newErrors);
          setLoading(false);
        }
      });
    } else if (type === "resetPassword") {
      resetPassword({ email }, (resetRes) => {
        if (resetRes.status === 200) {
          localStorage.setItem("signup-token", resetRes.data.token);
          setType("verify");
          setType2("resetPassword");
          if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("Recover password");
        } else {
          newErrors.email = resetRes.data.message;
          setErrors(newErrors);
          setLoadingEmail(false);
          setLoadingUsername(false);
        }
        setLoading(false);
      });
    }
  };

  //  Resend OTP method
  const resendCode = (e) => {
    if (e) e.preventDefault();
    setLoadingResendCode(true);
    if (process.env.REACT_APP_MIXPANEL === "1") mixpanel?.track("Resend verification code _clicked");
    if (process.env.REACT_APP_GA === "1") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(
        prepareData({
          event: "Resend verification code _clicked",
        }),
      );
    }
    resendOTP(
      {
        token: localStorage.getItem("signup-token"),
        type: type2 ? type2 : "registration",
      },
      (otpRes) => {
        localStorage.setItem("signup-token", otpRes.data.token);
        setLoadingResendCode(false);
        setOtpSent(true);
        setData({
          ...data,
          verificationCode1: "",
          verificationCode2: "",
          verificationCode3: "",
          verificationCode4: "",
        });
        setErrors({});
      },
    );
  };

  //  Method to check if email is valid or not
  const checkEmailValidity = (email) => {
    const newErrors = structuredClone(errors);

    if (!_.isEmpty(email)) {
      var mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!mailformat.test(email)) {
        newErrors.email = "Email is invalid";
        return setErrors(newErrors);
      }
      setLoadingEmail(true);
      checkEmail({ email }, (emailRes) => {
        if (emailRes.status === 200) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
          if (emailRes.data.message === '""email" must be a valid email"') {
            newErrors.email = "Email is invalid";
          } else newErrors.email = emailRes.data.message;
          setErrors(newErrors);
        }
        setLoadingEmail(false);
      });
    }
  };

  const checkEmailValidity2 = (email) => {
    const newErrors = structuredClone(errors);

    if (!_.isEmpty(email)) {
      var mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!mailformat.test(email)) {
        newErrors.email = "Email is invalid";
        return setErrors(newErrors);
      }
    }
  };

  //  Method to check if username is valid or not
  const checkUsernameValidity = (userName) => {
    if (type === "signup" && !_.isEmpty(userName)) {
      setLoadingUsername(true);
      checkUsername({ userName }, (usernameRes) => {
        const newErrors = structuredClone(errors);
        if (usernameRes.status === 200) {
          setIsValidUserName(true);
        } else {
          newErrors.userName = usernameRes.data.message;
          setErrors(newErrors);
          setIsValidUserName(false);
        }
        setLoadingUsername(false);
      });
    }
  };

  // JSX starts here
  return (
    <>
      <div
        className="modal-close-btn position-absolute d-flex"
        data-bs-dismiss="modal"
        onClick={(e) => {
          if (isVerificationComplete) {
            toggleHTPRedux(true);
            // togglePopupRedux(true);
          }
          toggleModal(e);
        }}
      >
        <img src={closeIcon} alt="" />
      </div>
      <div className="content-modal-box position-relative text-center">
        <div className="content-modal-header">
          <img className="content-modal-logo" src={logo} alt="" />
          <h5>{getTitle()}</h5>
          <p>{!finishSetup && getSubTitle()}</p>
        </div>
        {!isVerificationComplete ? (
          <div>
            {/* username */}
            {!finishSetup && type !== "verify" && type !== "resetPassword" && type !== "resetPassword2" && (
              <div
                className={`form-group ${loadingUsername && "loading"} ${
                  errors.userName
                    ? "is-invalid"
                    : !_.isEmpty(data.userName) && isValidUserName && !loadingUsername
                    ? "is-valid"
                    : ""
                }`}
              >
                <div className="form-group-input position-relative">
                  <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                    <img src={usernameIcon} alt="" />
                  </div>
                  <input
                    type="text"
                    className="w-100 h-100 new-form-control"
                    placeholder={
                      type === "login" ? "Please enter your username or email address" : "Please enter your username"
                    }
                    name="userName"
                    onChange={onChange}
                    onBlur={(e) => {
                      checkUsernameValidity(e.target.value);
                    }}
                    maxLength={type === "signup" ? 15 : false}
                    value={data.userName}
                  />
                  <img src={loader} className="input-loader fa-spin" alt="" />
                </div>
                {errors.userName && <div className="invalid-feedback text-start">{errors.userName}</div>}
              </div>
            )}
            {/* email */}
            {type === "signup" && !finishSetup && (
              <div
                className={`form-group ${loadingEmail && "loading"} ${
                  errors.email
                    ? "is-invalid"
                    : !_.isEmpty(data.email) && isValidEmail && !loadingEmail
                    ? "is-valid"
                    : ""
                }`}
              >
                <div className="form-group-input position-relative">
                  <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                    <img src={mailIcon} alt="" />
                  </div>
                  <input
                    type="text"
                    placeholder="Please enter your email address"
                    className="w-100 h-100 new-form-control"
                    name="email"
                    onChange={onChange}
                    value={data.email}
                    onBlur={(e) => {
                      checkEmailValidity(e.target.value);
                    }}
                  />{" "}
                  <img src={loader} className="input-loader fa-spin" alt="" />
                </div>
                {errors.email && <div className="invalid-feedback text-start">{errors.email}</div>}
              </div>
            )}
            {/* password */}
            {(type === "login" ||
              (type === "signup" && !finishSetup && _.isEmpty(walletDetails)) ||
              type === "resetPassword2") && (
              <div className={`form-group ${errors.password ? "is-invalid" : !_.isEmpty(data.password) ? "" : ""}`}>
                <div className="form-group-input position-relative">
                  <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                    <img src={passwordIcon} alt="" />
                  </div>
                  <div className="input-group">
                    <input
                      type={showPass ? "text" : "password"}
                      id="password1"
                      placeholder={type === "resetPassword2" ? "Enter your new password" : "Please enter your password"}
                      className="w-100 h-100 new-form-control"
                      name="password"
                      onChange={onChange}
                      value={data.password}
                    />
                    <div
                      className="eye-icon-box"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPass(!showPass);
                      }}
                    >
                      <img src={showPass ? eyeOffIcon : eyeIcon} alt="" />
                    </div>
                  </div>
                </div>{" "}
                {errors.password && <div className="invalid-feedback text-start">{errors.password}</div>}
              </div>
            )}
            {type === "verify" && (
              <div className={`form-group ${errors.verificationCode ? "is-invalid" : ""}`}>
                <div className="code-input-group d-flex flex-wrap justify-content-center">
                  <div className="form-group-input">
                    <input
                      type="number"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      pattern="[0-9]{1}"
                      className="w-100 h-100 new-form-control text-center"
                      name="verificationCode1"
                      id="verificationCode1"
                      value={data.verificationCode1}
                      onChange={onChange2}
                      ref={aRef}
                    />
                  </div>
                  <div className="form-group-input">
                    <input
                      type={"text"}
                      className="w-100 h-100 new-form-control text-center"
                      name="verificationCode2"
                      id="verificationCode2"
                      value={data.verificationCode2}
                      onChange={onChange2}
                      ref={bRef}
                    />
                  </div>
                  <div className="form-group-input">
                    <input
                      type={"text"}
                      className="w-100 h-100 new-form-control text-center"
                      name="verificationCode3"
                      id="verificationCode3"
                      value={data.verificationCode3}
                      onChange={onChange2}
                      ref={cRef}
                    />
                  </div>
                  <div className="form-group-input">
                    <input
                      type={"text"}
                      className="w-100 h-100 new-form-control text-center"
                      name="verificationCode4"
                      id="verificationCode4"
                      value={data.verificationCode4}
                      onChange={onChange2}
                      ref={dRef}
                    />
                  </div>
                  {errors.verificationCode && (
                    <div className="invalid-feedback text-center ">{errors.verificationCode}</div>
                  )}
                </div>
              </div>
            )}
            {finishSetup && (
              <div className={"form-group" + (errors.date ? " is-invalid" : "")}>
                <div className="form-label-area fw-bold d-flex">Date of Birth</div>
                <DateDropDown onChangeDate={onChangeDate} />
                {errors.date && <div className="invalid-feedback text-start">{errors.date}</div>}
              </div>
            )}
            {type === "signup" && finishSetup && (
              <div className="tick-checkbox-group">
                <div className={errors.term ? "form-check tick-checkbox is-invalid" : "form-check tick-checkbox"}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="tcCheckbox"
                    name="term"
                    onClick={(e) => handleCheck({ name: "term", checked: !data.term })}
                    checked={data.term}
                  />
                  <label className="form-check-label" for="tcCheckbox">
                    <div className="text-start subtext-onboarding">
                      By clicking the Agree and Create Account button below, I represent that I am at least 18 years of
                      age, I have read and agree to the{" "}
                      <a href="/terms-of-service" className="blank-blue-link" target="_blank">
                        Terms & Conditions
                      </a>
                      , and{" "}
                      <a href="/sweepstakes-rules" className="blank-blue-link" target="_blank">
                        Sweepstakes Rules
                      </a>
                      , and{" "}
                      <a href="/privacy-policy" className="blank-blue-link" target="_blank">
                        Privacy Policy
                      </a>{" "}
                      and agree to receive email marketing materials from Burn Ghost, Inc. and its partners.
                    </div>
                    {errors.term && (
                      <div className="invalid-feedback text-start">
                        Please confirm that you are at least 18 years of age and agree to the Burn Ghost Terms &
                        Conditions
                      </div>
                    )}
                  </label>
                </div>
              </div>
            )}
            {type === "resetPassword" && (
              <div
                className={`form-group ${loadingEmail && "loading"} ${
                  errors.email
                    ? "is-invalid"
                    : !_.isEmpty(data.email) && isValidEmail && !loadingEmail
                    ? "is-valid"
                    : ""
                }`}
              >
                <div className="form-group-input position-relative">
                  <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                    <img src={mailIcon} alt="" />
                  </div>
                  <input
                    type="text"
                    placeholder="Please enter your email address"
                    className="w-100 h-100 new-form-control"
                    name="email"
                    onChange={onChange}
                    value={data.email}
                    onBlur={(e) => {
                      checkEmailValidity2(e.target.value);
                      setErrors({});
                    }}
                  />{" "}
                  <img src={loader} className="input-loader fa-spin" alt="" />
                </div>
                {errors.email && <div className="invalid-feedback text-start">{errors.email}</div>}
              </div>
            )}
            {type === "resetPassword2" && (
              <div className={`form-group ${errors.cPassword ? "is-invalid" : !_.isEmpty(data.cPassword) ? "" : ""}`}>
                <div className="form-group-input position-relative">
                  <div className="form-group-input-icon d-flex align-items-center justify-content-center rounded-circle">
                    <img src={passwordIcon} alt="" />
                  </div>
                  <div className="input-group">
                    <input
                      type={showPass2 ? "text" : "password"}
                      id="password2"
                      placeholder="Confirm your new password"
                      className="w-100 h-100 new-form-control"
                      name="cPassword"
                      onChange={onChange}
                      value={data.cPassword}
                    />
                    <div
                      className="eye-icon-box"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPass2(!showPass2);
                      }}
                    >
                      <img src={showPass2 ? eyeOffIcon : eyeIcon} alt="" />
                    </div>
                  </div>
                </div>{" "}
                {errors.cPassword && <div className="invalid-feedback text-start">{errors.cPassword}</div>}
              </div>
            )}

            <div className="form-group-button">
              <button
                type="submit"
                className={`btn btn-gradient-default btn-block common-btn-gradient ${
                  (loading || loadingUsername || loadingEmail) && "disabled"
                } ${loading && "loading"}`}
                ref={eRef}
                onClick={handleSubmit}
              >
                {loading ? (
                  <LoaderImg />
                ) : type === "signup" ? (
                  finishSetup ? (
                    "Agree and Create Account"
                  ) : (
                    "Create Account"
                  )
                ) : type === "login" ? (
                  "Login Now"
                ) : type === "resetPassword" ? (
                  "Send Password Reset Email"
                ) : type === "resetPassword2" ? (
                  "Reset Password"
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="cradit-coin-widget border-radius-40 overflow-hidden text-uppercase">
            <div className="cradit-coin-widget-inside w-100 h-100 d-flex flex-column border-radius-40 overflow-hidden">
              <div className="cradit-coin-widget-upper w-100 h-100 d-flex flex-column border-radius-40 overflow-hidden">
                <div className="cradit-coin-widget-upper-content position-relative w-100 h-100 d-flex flex-column border-radius-40 overflow-hidden">
                  <div className="cradit-coin-widget-img">
                    <img src={creditImage} alt="" />
                  </div>
                </div>
              </div>
              <div className="cradit-coin-widget-lower">
                <div className="mct-credit-count">
                  <img src={creditIcon} alt="" /> {isVerificationComplete} CREDITS
                </div>
                <div className="mct-includes-credit">ADDed TO YOUR ACCOUNT</div>
              </div>
            </div>
          </div>
        )}

        {!isVerificationComplete ? (
          <div className="blank-label-box fw-semi-bold">
            {type === "signup" && (
              <span className="d-block">
                Existing Account?{" "}
                <a
                  href="#!"
                  className="blank-blue-link"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    setType("login");
                  }}
                >
                  {" "}
                  Login
                </a>{" "}
              </span>
            )}
            {type === "login" && (
              <>
                <span className="d-block">
                  Don’t have an account?{" "}
                  <a
                    href="#!"
                    className="blank-blue-link"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      setData({
                        email: "",
                        password: "",
                        cPassword: "",
                        userName: "",
                        verificationCode1: "",
                        verificationCode2: "",
                        verificationCode3: "",
                        verificationCode4: "",
                        term: false,
                        date: {},
                      });
                      setType("signup");
                    }}
                  >
                    {" "}
                    Create Account
                  </a>{" "}
                </span>
                <span className="d-block">
                  Forgot Password?{" "}
                  <a
                    href="#!"
                    className="blank-blue-link"
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      setType("resetPassword");
                    }}
                  >
                    {" "}
                    Reset Password
                  </a>{" "}
                </span>
              </>
            )}
            {type === "verify" && (
              <span className="d-block">
                {otpSent ? (
                  <span className="green">Your code has been resent</span>
                ) : (
                  <>
                    Didn’t receive a code?{" "}
                    {loadingResendCode ? (
                      <LoaderImg />
                    ) : (
                      <a
                        href="#!"
                        className={`blank-blue-link ${loading && "pointer-none"}`}
                        target="_blank"
                        onClick={resendCode}
                      >
                        {" "}
                        Resend Code
                      </a>
                    )}{" "}
                  </>
                )}
              </span>
            )}
          </div>
        ) : (
          <>
            <div className="form-group-button position-relative" style={{ zIndex: 999999 }}>
              <button
                type="submit"
                className="btn btn-gradient-default btn-block common-btn-gradient"
                onClick={(e) => {
                  toggleHTPRedux(true);
                  // togglePopupRedux(true);
                  toggleModal(e);
                }}
              >
                Continue
              </button>
            </div>
          </>
        )}
      </div>

      <ErrorPopup error={showError} setError={setShowError} title={`Error`} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  contestEntry: (data, callback) => dispatch(contestEntry(data, callback)),
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  loginUser: (data, callback) => dispatch(loginUser(data, callback)),
  verifyOTP: (data, callback) => dispatch(verifyOTP(data, callback)),
  checkUsername: (data, callback) => dispatch(checkUsername(data, callback)),
  checkEmail: (data, callback) => dispatch(checkEmail(data, callback)),
  updateImage: (data, callback) => dispatch(updateImage(data, callback)),
  registerUser: (data, callback) => dispatch(registerUser(data, callback)),
  resendOTP: (data, callback) => dispatch(resendOTP(data, callback)),
  resetPassword: (data, callback) => dispatch(resetPassword(data, callback)),
  togglePopupRedux: (data) => dispatch(togglePopupRedux(data)),
  toggleHTPRedux: (data) => dispatch(toggleHTPRedux(data)),
  toggleHowToPlayRedux: (data) => dispatch(toggleHowToPlayRedux(data)),
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
  loadBountyBoxes: (callback) => dispatch(loadBountyBoxes(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HowItWorks));
