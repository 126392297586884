import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import useSound from "use-sound";
const question = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/a.png";
const loot = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/b.png";
const arcade = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/c.png";
const star = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/d.png";
const xp = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/e.png";
const dice = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/f.png";
const spin = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/spin.mp3";
const win = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/win.mp3";
const press = process.env.REACT_APP_WEBSITE_ASSETS_URL + "sfx/press.mp3";

const Carousel = ({ spinState, setAllowClose, images, setShowSuccess }) => {
  //three carousels that make up spinner
  const carousels = [useRef(null), useRef(null), useRef(null)];
  //degrees for each carousel
  const [currDegs, setCurrDegs] = useState([0, 0, 0]);

  //sound effects
  const [playPress] = useSound(press);
  const [playSpin] = useSound(spin);
  const [playWin] = useSound(win);
  console.log("images:", images, spinState);

  const randomRotate = () => {
    //sound effects
    playPress();

    setTimeout(() => {
      playSpin();
    }, 300);

    setTimeout(() => {
      playWin();
    }, 1400);
    setTimeout(() => {
      setShowSuccess(true);
    }, 2500);

    const newDegs = carousels.map((_, index) => {
      // Find the correct target index from the API response
      const randomIndex = images.findIndex((image) => spinState?._id + "-" + spinState?.reels?.[index] === image?.real);
      if (randomIndex === -1) {
        console.error("Target not found in images array for reel:", index);
        return currDegs[index];
      }

      // Calculate the new angle to stop at the target index
      const newAngle = -(360 / images.length) * randomIndex;

      // Ensure multiple rotations while aligning to the correct item
      const rotations = 5; // Number of full rotations before stopping (can be adjusted)
      const totalAngle = newAngle + 360 * rotations;

      // Calculate the final degree by subtracting any extra rotation
      const finalAngle = currDegs[index] + 360 * rotations + newAngle - (currDegs[index] % 360);

      return finalAngle; // Set the final degrees for the reel
    });

    //triggers the animation useEffect
    setCurrDegs(newDegs);
  };

  useEffect(() => {
    //rotate the carousel to the new position
    carousels.forEach((carousel, index) => {
      gsap.to(carousel.current, {
        rotateY: currDegs[index],
        rotateX: 0,
        rotateZ: 90,
        duration: 0.75 + index * 0.45,
        transformOrigin: "center center",
        ease: "power2.inOut",
        onComplete: () => {
          setAllowClose(true);
        },
      });
    });
  }, [currDegs]);

  useEffect(() => {
    //prototype for number of spins
    if (spinState) {
      randomRotate();
    }
  }, [spinState]);

  return (
    <div className="spinnerdiv">
      <div className="carousel-container">
        <div className="perspective-container">
          {carousels.map((carouselRef, i) => (
            <div className="container" key={i}>
              <div className="carousel" ref={carouselRef}>
                {images.map((image, index) => (
                  <div
                    className={`item`}
                    key={`${index}-${i}`}
                    style={{
                      transform: `rotateY(${(360 / images.length) * index}deg) translateZ(${14 * images.length}px)`,
                    }}
                  >
                    {/* {index} */}
                    {/* {(360 / images.length) * index} */}
                    <img src={process.env.REACT_APP_CDNURL + image.realImage} alt={image.real} className="itemImage" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Carousel;
