import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
// import IndexPage from "./home/home";
// import Winners from "./contests/winners.jsx";
import { SkeletonTheme } from "react-loading-skeleton";
// import Profile from "./profile/profile";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import { getUser, loadSlides, toggleDiscordModalRedux } from "store/user";

//  Contests
// import Contests from "./contests/contests";
// import ContestDetails from "./contests/contestDetails";
// import ActiveContests from "./contests/activeContestsListing.jsx";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// import NotFound from "./common/404";
// import ServerError from "./common/500";
// import CheckWallet from "./common/checkWallet";
// import CheckWalletError from "./common/checkWalletError";
// import InvalidUserAction from "./common/invalidUserAction";
// import LandscapeOrientation from "./common/landscapeOrientation";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getCookie, setCookie } from "./utils/cookiesUtilities";
// import ConnectNFTAccess from "./access/connectNFT";

//  Sweepstakes
// import SweepstakesDetails from "sweepstakes/sweepsDetails";
// import SweepstakeListings from "sweepstakes/sweepsListing.jsx";
// import SweepstakeRulesListings from "sweepstakes/sweepsRulesListing.jsx";

//  Misc Components
// import PrivacyPolicy from "./miscScreens/privacyPolicy";
// import TermsAndConditions from "./miscScreens/termsAndCondition";
// import SweepstakesRules from "./miscScreens/sweepstakesRules.jsx";
import { useState } from "react";
// import UnderMaintance from "./common/503";

import { connect } from "react-redux";
import lazyWithPreload from "react-lazy-with-preload";
// import GameDemo from "gameDemo/gameDemo";
import BoardGame from "container/boardGame";
import { metaMask, metaMaskHooks } from "web3/connectors/metaMask";
import { coinbaseWallet, coinbaseWalletHooks } from "web3/connectors/coinbase";
import AppLogin from "appLogin";
// import Cookies from "common/cookies.jsx";
// import CrashGame from "container/crashGame.jsx";
// import CowitzerGame from "container/cowitzerGame.jsx";
// import OpenLootbox from "lootbox/openLootbox.jsx";
// import Leaderboard from "leaderboard/leaderboard.jsx";

const IndexPage = lazyWithPreload(() => import("./home/home"));
const Winners = lazyWithPreload(() => import("./contests/winners.jsx"));
const Profile = lazyWithPreload(() => import("./profile/profile"));
const Contests = lazyWithPreload(() => import("./contests/contests"));

const ContestDetails = lazyWithPreload(() => import("./contests/contestDetails"));

const ActiveContests = lazyWithPreload(() => import("./contests/activeContestsListing.jsx"));
const NotFound = lazyWithPreload(() => import("./common/404"));

const ServerError = lazyWithPreload(() => import("./common/500"));
const CheckWallet = lazyWithPreload(() => import("./common/checkWallet"));
const CheckWalletError = lazyWithPreload(() => import("./common/checkWalletError"));
const InvalidUserAction = lazyWithPreload(() => import("./common/invalidUserAction"));
const LandscapeOrientation = lazyWithPreload(() => import("./common/landscapeOrientation"));

const ConnectNFTAccess = lazyWithPreload(() => import("./access/connectNFT"));
//  Sweepstakes
const SweepstakesDetails = lazyWithPreload(() => import("sweepstakes/sweepsDetails"));
// const SweepstakeListings = lazyWithPreload(() => import("sweepstakes/sweepsListing.jsx"));
const SweepstakeRulesListings = lazyWithPreload(() => import("sweepstakes/sweepsRulesListing.jsx"));

//  Misc Components
const PrivacyPolicy = lazyWithPreload(() => import("./miscScreens/privacyPolicy"));
const TermsAndConditions = lazyWithPreload(() => import("./miscScreens/termsAndCondition"));

const SweepstakesRules = lazyWithPreload(() => import("./miscScreens/sweepstakesRules.jsx"));
const UnderMaintance = lazyWithPreload(() => import("./common/503"));
const Cookies = lazyWithPreload(() => import("common/cookies.jsx"));
const CrashGame = lazyWithPreload(() => import("container/crashGame.jsx"));

const CowitzerGame = lazyWithPreload(() => import("container/cowitzerGame.jsx"));
const OpenLootbox = lazyWithPreload(() => import("lootbox/openLootbox.jsx"));
// const Leaderboard = lazyWithPreload(() => import("leaderboard/leaderboard.jsx"));
const Roadmap = lazyWithPreload(() => import("roadmap/roadmap"));
const About = lazyWithPreload(() => import("about/about"));
const GameDemo = lazyWithPreload(() => import("gameDemo/gameDemo"));

LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
  mergeIframes: true,
  childDomains: ["https://dev-games.burnghost.com"],
});
if (process.env.REACT_APP_MIXPANEL === "1") {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
}
setupLogRocketReact(LogRocket);

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

const App = ({ getUser, toggleDiscordModalRedux, loadSlides }) => {
  const location = useLocation();
  const themeMode = getCookie("themeMode");
  const [transitionStage, setTransistionStage] = useState("fadeInTransition");

  const [displayLocation, setDisplayLocation] = React.useState(location);

  //preload pages
  useEffect(() => {
    IndexPage?.preload();
    // SweepstakeListings?.preload();
    Contests?.preload();
    Winners?.preload();
    // Leaderboard?.preload();
    Profile?.preload();
    CheckWallet?.preload();
    CheckWalletError?.preload();

    ActiveContests?.preload();
    ContestDetails?.preload();
    UnderMaintance?.preload();
    InvalidUserAction?.preload();
    ServerError?.preload();
    NotFound?.preload();
    PrivacyPolicy?.preload();
    TermsAndConditions?.preload();
    ConnectNFTAccess?.preload();

    SweepstakesDetails?.preload();
    SweepstakeRulesListings?.preload();
    SweepstakesRules?.preload();

    CrashGame?.preload();
    CowitzerGame?.preload();
    OpenLootbox?.preload();
    LandscapeOrientation?.preload();
    Cookies?.preload();
    Roadmap?.preload();
    About?.preload();
    GameDemo?.preload();
    if (location.pathname === "/") loadSlides();
  }, []);

  useEffect(() => {
    if ("/game-result-new" === location.pathname) {
      if (location !== displayLocation) setTransistionStage("fadeOutTransition");
    } else {
      setDisplayLocation(location);
    }
  }, [location, displayLocation]);

  //ScrollToTop
  useEffect(() => {
    window.scrollTo(0, 0);
    let _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setPath", location.pathname]);
    _hsq.push(["trackPageView"]);
  }, [location.pathname]);

  const cookiesShown = getCookie("notifyCookies");
  const connectors = [
    [metaMask, metaMaskHooks],
    [coinbaseWallet, coinbaseWalletHooks],
  ];
  return (
    <HelmetProvider>
      {/* <SEO title="Burn Ghost" description="A web3 casual game platform." keywords="web3, nft, contest" /> */}
      <Web3ReactProvider connectors={connectors}>
        <SkeletonTheme
          baseColor={themeMode === "dark" ? "#202020" : "#ebebeb"}
          highlightColor={themeMode === "dark" ? "#444" : "#f5f5f5"}
        >
          {process.env.REACT_APP_GA === "1" ? (
            <Helmet>
              <meta name="robots" content="index,follow" />
            </Helmet>
          ) : (
            <Helmet>
              <meta name="robots" content="noindex,nofollow" />
            </Helmet>
          )}
          <div
            className={"/game-result-new" === location.pathname ? `${transitionStage}` : ""}
            onAnimationEnd={() => {
              if (transitionStage === "fadeOutTransition") {
                setTransistionStage("fadeInTransition");
                setDisplayLocation(location);
              }
            }}
          >
            {localStorage.getItem("appLogin") !== "true" && !cookiesShown && (
              <Suspense fallback={<></>}>
                <Cookies />
              </Suspense>
            )}
            <Suspense fallback={<></>}>
              <Routes location={displayLocation}>
                {/* Profile */}
                <Route path="/app-login" element={<AppLogin />} />
                <Route path="/account" element={<Profile />} />
                <Route path="/connect" element={<CheckWallet />} />
                <Route path="/connect-error" element={<CheckWalletError />} />

                {/* Contests */}
                <Route path="/contests" element={<Contests />} />
                <Route path="/active-contests" element={<ActiveContests />} />
                <Route path="/games/:slug/:contestSlug" element={<ContestDetails />} />
                <Route path="/winners" element={<Winners />} />
                <Route path="/" element={<IndexPage />} />
                <Route path="/referral/:referralCode" element={<IndexPage />} />
                <Route path="/campaign/:campaignCode" element={<IndexPage />} />

                {/* Miscellaneous */}
                <Route path="/maintenance" element={<UnderMaintance />} />
                <Route path="/invalid-user-action" element={<InvalidUserAction />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/500" element={<ServerError />} />
                <Route path="*" element={<NotFound />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsAndConditions />} />
                <Route path="/restricted-access" element={<ConnectNFTAccess />} />

                {/* Sweepstakes */}
                <Route path="/sweeps/:slug" exact element={<SweepstakesDetails />} />
                {/* <Route path="/prizes" exact element={<SweepstakeListings />} /> */}
                <Route path="/sweepstakes-rules" exact element={<SweepstakeRulesListings />} />
                <Route path="/sweepstakes-generic-rules" exact element={<SweepstakesRules />} />

                {/* Crash game  */}
                <Route path="/crash-game/:slug" element={<CrashGame />} />
                {/* Cowitzer game  */}
                <Route path="/cowitzer-game/:slug" element={<CowitzerGame />} />
                {/* Board game  */}
                <Route path="/board-game/:slug" element={<BoardGame />} />
                {/* Lootbox  */}
                <Route path="/lootbox/:slug" element={<OpenLootbox />} />
                {/* Leaderboard */}
                {/* <Route path="/leaderboard" element={<Leaderboard />} /> */}
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/about" element={<About />} />
                <Route path="/demo" element={<GameDemo />} />
              </Routes>
            </Suspense>
          </div>
          <Suspense fallback={<></>}>
            <LandscapeOrientation showModal={false} />
          </Suspense>
        </SkeletonTheme>
      </Web3ReactProvider>
    </HelmetProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleDiscordModalRedux: (data) => dispatch(toggleDiscordModalRedux(data)),
  loadSlides: () => dispatch(loadSlides()),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
