import React from "react";

const IframeComponent = (props) => {
  return (
    <>
      <iframe
        style={{ border: "none" }}
        id="iframe-container"
        className="page-card-bg"
        title="ghostrunner"
        src={props.gameUrl}
      ></iframe>
    </>
  );
};

export default IframeComponent;
