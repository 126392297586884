import Carousel from "./carousel";
import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { getGame, loadSpinReward, loadSpinRewardsList, loadSlotRewardsList, loadSloReward } from "store/game";
import Skeleton from "react-loading-skeleton";
import LoaderImg from "common/loaderImag";
import ConfettiJSON from "../include/confetti.json";
import Lottie from "react-lottie-player";
import { createRef } from "react";

const lottieRef = createRef(null);
const successIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/winner-tropy-01.png";

const MetagameSpinner = ({
  toggle,
  loadSpinRewardsList,
  loadSpinReward,
  handleClose,
  getAvailableDiceCall,
  loadSlotRewardsList,
  loadSloReward,
}) => {
  const [spin, setSpin] = useState(0);
  const [rewards, setRewards] = useState(null);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allowClose, setAllowClose] = useState(false);
  const [spinReward, setSpinReward] = useState(null);
  const [slotReward, setSlotReward] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);

  // if allowClose changed to tru then call getAvailableDiceCall
  useEffect(() => {
    if (allowClose) {
      getAvailableDiceCall();
    }
  }, [allowClose]);

  //reset the spin slottate to false when the toggle button is clicked
  useEffect(() => {
    if (toggle) {
      loadSlotRewardsList((res) => {
        if (res.status === 200) {
          setRewards(res?.data?.data?.[0]);
          setSpinnerLoading(false);
        }
      });
    } else {
      loadSpinRewardsList((res) => {
        if (res.status === 200) {
          setRewards(res?.data?.data);
          setSpinnerLoading(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    setSpin(0);
  }, [toggle]);
  function handleSpin() {
    if (toggle && spin < 10) {
      setLoading(true);
      loadSloReward((res) => {
        if (res.status === 200) {
          setSlotReward(res?.data?.data);
        }
        setLoading(false);
      });
      setSpin(spin + 1);
    } else if (!toggle && spin === 0) {
      setLoading(true);
      loadSpinReward((res) => {
        if (res.status === 200) {
          setSpinReward(res?.data?.data);
        }
        setLoading(false);
      });
      setSpin(spin + 1);
    }
  }
  useEffect(() => {
    if (showSuccess)
      setTimeout(() => {
        setShowConfetti(false);
      }, 3500);
  }, [showSuccess]);

  console.log("slot:", slotReward);
  return (
    <div className="modal-div position-absolute top-50 start-50 translate-middle">
      {spinnerLoading ? (
        <>
          <Skeleton width="100%" containerClassName="d-block lh-1" style={{ aspectRatio: 1 / 1 }} />
          <Skeleton width="100%" containerClassName="d-block lh-1" height={60} style={{ marginTop: 12 }} />
        </>
      ) : (
        <>
          {showSuccess ? (
            <div className="custom-row-body">
              <div className="custom-number-area text-center text-white mt-0">
                <div className="cn-title">{spinReward ? spinReward.value : slotReward?.rewardValue} Dice</div>
                <div className="ct-text-box">
                  <p>
                    You won {spinReward ? spinReward.value : slotReward?.rewardValue} dice throws that you can use in
                    the metagame
                  </p>
                </div>
                <div className="cn-img-box">
                  <img src={successIcon} alt="trophy" />
                </div>
              </div>
              {showConfetti && (
                <div className={`lottie-player-fixed`} style={{ zIndex: 999992 }}>
                  <Lottie
                    background="transparent"
                    speed="0.9"
                    loop={false}
                    play
                    ref={lottieRef}
                    animationData={ConfettiJSON}
                  />
                </div>
              )}
            </div>
          ) : !toggle ? (
            <Spinner
              spinState={spinReward}
              sections={rewards}
              setAllowClose={setAllowClose}
              setShowSuccess={setShowSuccess}
            />
          ) : (
            <Carousel
              spinState={slotReward}
              images={rewards}
              setAllowClose={setAllowClose}
              setShowSuccess={setShowSuccess}
            />
          )}
          <button
            className={"spinButton" + (spin === 1 && !showSuccess ? " disabled loading" : "")}
            disabled={spin === 1 && !showSuccess}
            onClick={() => (spin === 1 ? (showSuccess ? handleClose() : null) : handleSpin())}
          >
            {loading ? <LoaderImg /> : <>{spin === 1 ? "Close" : "Spin"}</>}
          </button>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadSpinRewardsList: (callback) => dispatch(loadSpinRewardsList(callback)),
  loadSpinReward: (callback) => dispatch(loadSpinReward(callback)),
  loadSlotRewardsList: (callback) => dispatch(loadSlotRewardsList(callback)),
  loadSloReward: (callback) => dispatch(loadSloReward(callback)),
});
const mapStateToProps = (state) => ({
  getGame: getGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetagameSpinner));
