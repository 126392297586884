export function SpinnerSVG() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 2694 2695"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="spinnersvg"
    >
      <g>
        <path
          d="M2322.78 779.882C2223.5 608.43 2081.04 465.972 1909.59 366.691L1345.17 1344.3H2473.84C2474.14 1146.13 2422.03 951.406 2322.78 779.882Z"
          fill="var(--top-right)"
        />
      </g>
      <path
        d="M1346.9 1343.3L1909.95 368.059C2080.87 467.23 2222.89 609.371 2321.91 780.383C2420.91 951.466 2472.97 1145.65 2472.84 1343.3H1346.9Z"
        stroke="white"
        strokeWidth="2"
      />
      <g>
        <path
          d="M1345.18 1344.3L780.762 2321.91C952.403 2420.87 1147.05 2472.97 1345.18 2472.97C1543.31 2472.97 1737.95 2420.87 1909.59 2321.91L1345.18 1344.3Z"
          fill="var(--bottom-center)"
        />
      </g>
      <path
        d="M1908.23 2321.54C1736.95 2420.1 1542.8 2471.97 1345.18 2471.97C1147.56 2471.97 953.406 2420.1 782.128 2321.54L1345.18 1346.3L1908.23 2321.54Z"
        stroke="white"
        strokeWidth="2"
      />
      <g>
        <path
          d="M1345.17 1344.3L1909.59 2321.91C2081.21 2222.86 2223.71 2080.36 2322.77 1908.74C2421.82 1737.13 2473.92 1542.45 2473.84 1344.3H1345.17Z"
          fill="var(--bottom-right)"
        />
      </g>
      <path
        d="M1909.95 2320.54L1346.9 1345.3H2472.84C2472.75 1542.93 2420.7 1737.07 2321.9 1908.24C2223.1 2079.42 2081.03 2221.6 1909.95 2320.54Z"
        stroke="white"
        strokeWidth="2"
      />
      <g>
        <path
          d="M1345.18 215.63C1147.01 215.327 952.286 267.443 780.762 366.693L1345.18 1344.3L1909.59 366.693C1738.07 267.443 1543.35 215.327 1345.18 215.63Z"
          fill="var(--top-center)"
        />
      </g>
      <path
        d="M1345.18 216.63L1345.18 216.63C1542.84 216.327 1737.07 268.222 1908.23 367.058L1345.18 1342.3L782.127 367.058C953.288 268.222 1147.52 216.327 1345.18 216.63Z"
        stroke="white"
        strokeWidth="2"
      />
      <g>
        <path
          d="M780.757 366.691C609.138 465.739 466.633 608.24 367.58 779.856C268.528 951.472 216.421 1146.15 216.504 1344.3H1345.17L780.757 366.691Z"
          fill="var(--top-left)"
        />
      </g>
      <path
        d="M368.446 780.356C467.245 609.181 609.311 466.997 780.391 368.058L1343.44 1343.3H217.504C217.597 1145.67 269.648 951.531 368.446 780.356Z"
        stroke="white"
        strokeWidth="2"
      />
      <g>
        <path
          d="M1345.17 1344.3H216.504C216.421 1542.45 268.528 1737.13 367.58 1908.74C466.633 2080.36 609.138 2222.86 780.757 2321.91L1345.17 1344.3Z"
          fill="var(--bottom-left)"
        />
      </g>
      <path
        d="M217.504 1345.3H1343.44L780.391 2320.54C609.311 2221.6 467.245 2079.42 368.446 1908.24C269.648 1737.07 217.597 1542.93 217.504 1345.3Z"
        stroke="white"
        strokeWidth="2"
      />

      <g>
        <path
          d="M1345.18 215.631C1968.52 215.631 2473.85 720.958 2473.85 1344.3C2473.85 1967.64 1968.52 2472.97 1345.18 2472.97C721.835 2472.97 216.507 1967.64 216.507 1344.3C216.507 720.958 721.835 215.631 1345.18 215.631ZM1345.18 141.399C1263.78 141.383 1182.58 149.571 1102.82 165.836C948.912 197.393 802.716 258.939 672.575 346.962C479.221 477.479 327.866 661.267 236.847 876.059C206.073 948.856 182.58 1024.52 166.712 1101.95C134.13 1261.87 134.13 1426.73 166.712 1586.65C198.27 1740.57 259.815 1886.76 347.839 2016.9C478.375 2210.27 662.19 2361.62 877.01 2452.63C949.807 2483.4 1025.47 2506.9 1102.9 2522.76C1262.82 2555.35 1427.68 2555.35 1587.6 2522.76C1741.52 2491.21 1887.71 2429.66 2017.85 2341.64C2211.22 2211.1 2362.57 2027.29 2453.58 1812.47C2484.36 1739.67 2507.85 1664 2523.72 1586.58C2556.3 1426.65 2556.3 1261.8 2523.72 1101.87C2492.16 947.961 2430.61 801.765 2342.59 671.624C2212.05 478.259 2028.24 326.903 1813.42 235.896C1740.62 205.122 1664.96 181.629 1587.53 165.762C1507.77 149.521 1426.57 141.359 1345.18 141.399Z"
          fill="white"
        />
      </g>
      <path
        d="M1345.18 2516.03C1055.56 2515.64 776.407 2407.7 561.872 2213.14C347.336 2018.58 212.712 1751.27 184.113 1463.07C155.514 1174.87 234.98 886.314 407.095 653.387C579.211 420.46 831.706 259.762 1115.6 202.468C1399.49 145.173 1694.55 195.366 1943.53 343.309C2192.51 491.252 2377.67 726.4 2463.09 1003.14C2548.5 1279.87 2528.09 1578.47 2405.8 1841.01C2283.51 2103.54 2068.07 2311.3 1801.27 2423.98C1656.98 2485.03 1501.85 2516.34 1345.18 2516.03ZM1345.18 293.798C765.929 293.798 294.674 765.053 294.674 1344.3C294.674 1923.55 765.929 2394.8 1345.18 2394.8C1924.43 2394.8 2395.68 1923.55 2395.68 1344.3C2395.68 765.053 1924.43 293.798 1345.18 293.798Z"
        fill="#323232"
      />

      <path
        d="M1902.09 427.283C1925.08 427.283 1943.73 407.195 1943.73 382.417C1943.73 357.638 1925.08 337.551 1902.09 337.551C1879.09 337.551 1860.44 357.638 1860.44 382.417C1860.44 407.195 1879.09 427.283 1902.09 427.283Z"
        fill="#333333"
      />
      <path
        d="M1902.09 420.839C1925.08 420.839 1943.73 402.194 1943.73 379.195C1943.73 356.196 1925.08 337.551 1902.09 337.551C1879.09 337.551 1860.44 356.196 1860.44 379.195C1860.44 402.194 1879.09 420.839 1902.09 420.839Z"
        fill="white"
      />
      <path
        d="M1902.08 412.911C1918.37 412.911 1931.58 398.76 1931.58 381.303C1931.58 363.847 1918.37 349.695 1902.08 349.695C1885.79 349.695 1872.58 363.847 1872.58 381.303C1872.58 398.76 1885.79 412.911 1902.08 412.911Z"
        fill="#333333"
      />
      <path
        d="M1902.08 408.695C1918.37 408.695 1931.58 395.487 1931.58 379.195C1931.58 362.903 1918.37 349.695 1902.08 349.695C1885.79 349.695 1872.58 362.903 1872.58 379.195C1872.58 395.487 1885.79 408.695 1902.08 408.695Z"
        fill="#F0EA8F"
      />
      <path
        d="M1913.99 386.904C1920.57 380.323 1920.57 369.654 1913.99 363.073C1907.41 356.492 1896.74 356.492 1890.16 363.073C1883.58 369.654 1883.58 380.323 1890.16 386.904C1896.74 393.484 1907.41 393.484 1913.99 386.904Z"
        fill="white"
      />

      <path
        d="M2459 1391.88C2482 1391.88 2500.65 1371.8 2500.65 1347.02C2500.65 1322.24 2482 1302.15 2459 1302.15C2436 1302.15 2417.36 1322.24 2417.36 1347.02C2417.36 1371.8 2436 1391.88 2459 1391.88Z"
        fill="#333333"
      />
      <path
        d="M2488.45 1373.24C2504.71 1356.98 2504.71 1330.61 2488.45 1314.35C2472.19 1298.08 2445.82 1298.08 2429.55 1314.35C2413.29 1330.61 2413.29 1356.98 2429.55 1373.24C2445.82 1389.5 2472.18 1389.5 2488.45 1373.24Z"
        fill="white"
      />
      <path
        d="M2459 1377.51C2475.3 1377.51 2488.5 1363.36 2488.5 1345.9C2488.5 1328.45 2475.3 1314.3 2459 1314.3C2442.71 1314.3 2429.5 1328.45 2429.5 1345.9C2429.5 1363.36 2442.71 1377.51 2459 1377.51Z"
        fill="#333333"
      />
      <path
        d="M2479.85 1364.65C2491.37 1353.13 2491.37 1334.45 2479.85 1322.93C2468.33 1311.41 2449.66 1311.41 2438.13 1322.93C2426.61 1334.45 2426.61 1353.13 2438.13 1364.65C2449.66 1376.17 2468.33 1376.17 2479.85 1364.65Z"
        fill="#F0EA8F"
      />
      <path
        d="M2459.01 1356.43C2468.31 1356.43 2475.86 1348.89 2475.86 1339.58C2475.86 1330.27 2468.31 1322.73 2459.01 1322.73C2449.7 1322.73 2442.16 1330.27 2442.16 1339.58C2442.16 1348.89 2449.7 1356.43 2459.01 1356.43Z"
        fill="white"
      />

      <path
        d="M1902.09 2356.49C1925.08 2356.49 1943.73 2336.4 1943.73 2311.62C1943.73 2286.84 1925.08 2266.75 1902.09 2266.75C1879.09 2266.75 1860.44 2286.84 1860.44 2311.62C1860.44 2336.4 1879.09 2356.49 1902.09 2356.49Z"
        fill="#333333"
      />
      <path
        d="M1902.09 2350.04C1925.08 2350.04 1943.73 2331.4 1943.73 2308.4C1943.73 2285.4 1925.08 2266.75 1902.09 2266.75C1879.09 2266.75 1860.44 2285.4 1860.44 2308.4C1860.44 2331.4 1879.09 2350.04 1902.09 2350.04Z"
        fill="white"
      />
      <path
        d="M1902.08 2342.1C1918.37 2342.1 1931.58 2327.95 1931.58 2310.49C1931.58 2293.03 1918.37 2278.88 1902.08 2278.88C1885.79 2278.88 1872.58 2293.03 1872.58 2310.49C1872.58 2327.95 1885.79 2342.1 1902.08 2342.1Z"
        fill="#333333"
      />
      <path
        d="M1902.08 2337.9C1918.37 2337.9 1931.58 2324.69 1931.58 2308.4C1931.58 2292.11 1918.37 2278.9 1902.08 2278.9C1885.79 2278.9 1872.58 2292.11 1872.58 2308.4C1872.58 2324.69 1885.79 2337.9 1902.08 2337.9Z"
        fill="#F0EA8F"
      />
      <path
        d="M1902.08 2321.03C1911.39 2321.03 1918.93 2313.49 1918.93 2304.18C1918.93 2294.88 1911.39 2287.33 1902.08 2287.33C1892.78 2287.33 1885.23 2294.88 1885.23 2304.18C1885.23 2313.49 1892.78 2321.03 1902.08 2321.03Z"
        fill="white"
      />

      <path
        d="M788.271 2356.48C811.271 2356.48 829.915 2336.39 829.915 2311.62C829.915 2286.84 811.271 2266.75 788.271 2266.75C765.272 2266.75 746.627 2286.84 746.627 2311.62C746.627 2336.39 765.272 2356.48 788.271 2356.48Z"
        fill="#333333"
      />
      <path
        d="M788.271 2350.04C811.271 2350.04 829.915 2331.39 829.915 2308.39C829.915 2285.39 811.271 2266.75 788.271 2266.75C765.272 2266.75 746.627 2285.39 746.627 2308.39C746.627 2331.39 765.272 2350.04 788.271 2350.04Z"
        fill="white"
      />
      <path
        d="M788.273 2342.09C804.566 2342.09 817.773 2327.94 817.773 2310.49C817.773 2293.03 804.566 2278.88 788.273 2278.88C771.981 2278.88 758.773 2293.03 758.773 2310.49C758.773 2327.94 771.981 2342.09 788.273 2342.09Z"
        fill="#333333"
      />
      <path
        d="M788.273 2337.89C804.566 2337.89 817.773 2324.69 817.773 2308.39C817.773 2292.1 804.566 2278.89 788.273 2278.89C771.981 2278.89 758.773 2292.1 758.773 2308.39C758.773 2324.69 771.981 2337.89 788.273 2337.89Z"
        fill="#F0EA8F"
      />
      <path
        d="M788.277 2321.03C797.583 2321.03 805.127 2313.49 805.127 2304.18C805.127 2294.87 797.583 2287.33 788.277 2287.33C778.97 2287.33 771.426 2294.87 771.426 2304.18C771.426 2313.49 778.97 2321.03 788.277 2321.03Z"
        fill="white"
      />

      <path
        d="M231.351 1391.88C254.351 1391.88 272.995 1371.8 272.995 1347.02C272.995 1322.24 254.351 1302.15 231.351 1302.15C208.352 1302.15 189.707 1322.24 189.707 1347.02C189.707 1371.8 208.352 1391.88 231.351 1391.88Z"
        fill="#333333"
      />
      <path
        d="M271.919 1353.2C277.139 1330.8 263.214 1308.41 240.814 1303.19C218.415 1297.97 196.025 1311.89 190.804 1334.29C185.584 1356.69 199.51 1379.08 221.909 1384.3C244.308 1389.52 266.698 1375.6 271.919 1353.2Z"
        fill="white"
      />
      <path
        d="M231.353 1377.51C247.646 1377.51 260.853 1363.36 260.853 1345.9C260.853 1328.45 247.646 1314.3 231.353 1314.3C215.061 1314.3 201.854 1328.45 201.854 1345.9C201.854 1363.36 215.061 1377.51 231.353 1377.51Z"
        fill="#333333"
      />
      <path
        d="M260.089 1350.57C263.801 1334.7 253.95 1318.83 238.086 1315.12C222.223 1311.41 206.353 1321.26 202.641 1337.12C198.929 1352.99 208.78 1368.86 224.644 1372.57C240.508 1376.28 256.377 1366.43 260.089 1350.57Z"
        fill="#F0EA8F"
      />
      <path
        d="M231.357 1356.43C240.663 1356.43 248.207 1348.89 248.207 1339.58C248.207 1330.27 240.663 1322.73 231.357 1322.73C222.05 1322.73 214.506 1330.27 214.506 1339.58C214.506 1348.89 222.05 1356.43 231.357 1356.43Z"
        fill="white"
      />

      <path
        d="M788.271 427.283C811.271 427.283 829.915 407.195 829.915 382.417C829.915 357.638 811.271 337.551 788.271 337.551C765.272 337.551 746.627 357.638 746.627 382.417C746.627 407.195 765.272 427.283 788.271 427.283Z"
        fill="#333333"
      />
      <path
        d="M788.271 420.839C811.271 420.839 829.915 402.194 829.915 379.195C829.915 356.196 811.271 337.551 788.271 337.551C765.272 337.551 746.627 356.196 746.627 379.195C746.627 402.194 765.272 420.839 788.271 420.839Z"
        fill="white"
      />
      <path
        d="M788.275 412.911C804.568 412.911 817.775 398.76 817.775 381.303C817.775 363.847 804.568 349.695 788.275 349.695C771.983 349.695 758.775 363.847 758.775 381.303C758.775 398.76 771.983 412.911 788.275 412.911Z"
        fill="#333333"
      />
      <path
        d="M788.273 408.695C804.566 408.695 817.773 395.487 817.773 379.195C817.773 362.903 804.566 349.695 788.273 349.695C771.981 349.695 758.773 362.903 758.773 379.195C758.773 395.487 771.981 408.695 788.273 408.695Z"
        fill="#F0EA8F"
      />
      <path
        d="M804.68 378.886C806.828 369.831 801.23 360.749 792.175 358.6C783.12 356.451 774.038 362.05 771.889 371.105C769.74 380.16 775.339 389.242 784.393 391.391C793.448 393.54 802.531 387.941 804.68 378.886Z"
        fill="white"
      />
    </svg>
  );
}
