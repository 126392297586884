import React from "react";
import { withRouter } from "utils/withRouter";

const AppLogin = (props) => {
  console.log("AppLogin props", props);
  const { location } = props.router;
  const { search } = location;

  if (!search) {
    // Redirect to login page
    window.location.href = "/";
  } else {
    // Extract token from search
    const token = search.split("=")[1];
    if (token) {
      // Save token to local storage
      localStorage.setItem("x-auth-token", token);
      localStorage.setItem("userProfile", JSON.stringify({ userName: "app-login", email: "appLogin" }));
      localStorage.setItem("appLogin", "true");
      // Redirect to home page
      window.location.href = "/board-game/board";
    } else {
      // Redirect to login page
      window.location.href = "/";
    }
  }
  return <h5>Loading...</h5>;
};

export default withRouter(AppLogin);
