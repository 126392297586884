import React, { useEffect } from "react";

const PieSection = ({ position, text, imageSrc, color }) => {
  useEffect(() => {
    // Set root color of the section based on the position prop
    document.documentElement.style.setProperty(`--${position}`, color);
  }, [position, color]);

  return (
    <div className={`pie-section ${position || ""}`}>
      <div className={`sectiontext ${position || ""}`}>{text}</div>
      <img src={imageSrc} alt="" className={`sectionimage ${position || ""}`} />
    </div>
  );
};

export default PieSection;
