import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import _ from "lodash";
import moment from "moment";

const slice = createSlice({
  name: "boardGame",
  initialState: {
    error: null,
    loadingGame: false,
    id: null,
    transactionId: null,
    gameStartedAt: null,
    question: null,
    loadingGameQuestion: true,
    submittingAnswer: false,
    answerData: null,
    loadingGameResult: true,
    gameOver: null,
    gameContest: null,
    gameContestLoading: true,
    removeOneLoading: false,
    removed: false,
    bgGames: {},
    demoBgGames: [],
    bgGamesLoading: false,
    demoBgGamesLoading: false,
    gameId: null,
    lastGameCreatedAt: null,
    lastScoreSavedAt: null,
    lastFinalScoreSavedAt: null,
    bgGameDetails: {},
    cowitzerLaunching: false,
    //  Share score
    finalScore: null,
    shareScoreLoading: false,
    shareGameScoreDetails: null,
    finalScoreResponseStatus: null,
    boardGame: {},
    gameData: null,
    gameScore: 0,
    startTimer: false,
    lastFetchBgGames: null,
  },

  reducers: {
    //boardGame
    boardGameLoaded: (game, action) => {
      game.boardGame = action.payload;
    },

    // Requesting a game
    prepareGameRequested: (game, action) => {
      game.loadingGame = true;
      game.id = null;
      game.question = null;
    },

    // Cowitzer launching
    cowitzerLaunching: (game, action) => {
      game.cowitzerLaunching = true;
    },

    //Cowitzer Launched
    cowitzerLaunched: (game, action) => {
      game.cowitzerLaunching = false;
    },

    prepareGameReceived: (game, action) => {
      game.loadingGame = false;
      game.id = action.payload.game;
      game.gameStartedAt = new Date().valueOf();
    },
    prepareGameRequestFailed: (game, action) => {
      game.loadingGame = false;
      game.error = action.payload.message;
    },

    // Requesting a question
    gameQuestionRequested: (game, action) => {
      game.loadingGameQuestion = true;
    },
    gameQuestionReceived: (game, action) => {
      game.loadingGameQuestion = false;
      game.question = action.payload;
    },
    gameQuestionRequestFailed: (game, action) => {
      game.loadingGameQuestion = false;
      game.error = action.payload.message;
      game.question = null;
    },

    // Submitting an answer
    answerSubmitted: (game, action) => {
      game.submittingAnswer = true;
    },
    answerSubmittedSuccess: (game, action) => {
      game.loadingGameQuestion = true;
      game.submittingAnswer = false;
      game.answerData = action.payload;
    },
    answerSubmittedFailed: (game, action) => {
      game.submittingAnswer = false;
      game.error = action.payload.message;
    },

    // Requesting a game result
    gameResultRequested: (game, action) => {
      game.loadingGameResult = true;
      game.id = null;
      game.question = null;
      game.answerData = null;
    },
    gameResultReceived: (game, action) => {
      game.loadingGameResult = false;
      game.gameOver = action.payload;
    },
    gameResultRequestFailed: (game, action) => {
      game.loadingGameResult = false;
      game.error = action.payload.message;
      game.gameOver = null;
    },

    // Requesting a game contest
    gameContestRequested: (game, action) => {
      game.gameContestLoading = true;
    },
    gameContestReceived: (game, action) => {
      game.gameContestLoading = false;
      game.gameContest = action.payload;
    },
    gameContestRequestFailed: (game, action) => {
      game.gameContestLoading = false;
      game.error = action.payload.message;
      game.gameContest = null;
    },

    // Requesting a game contest
    removeOneRequested: (game, action) => {
      game.loading = true;
    },
    removeOneReceived: (game, action) => {
      game.removed = action.payload.removed;
    },
    removeOneRequestFailed: (game, action) => {
      game.loading = false;
      game.error = action.payload.message;
    },

    //  Requesting BG Games
    bgGamesRequested: (game, action) => {
      game.bgGamesLoading = true;
      game.gameScore = 0;
      game.startTimer = false;
    },
    bgGamesReceived: (game, action) => {
      game.bgGamesLoading = false;
      game.bgGames = action.payload.data;
      game.lastFetchBgGames = Date.now();
    },
    bgGamesRequestFailed: (game, action) => {
      game.bgGamesLoading = false;
      game.error = action.payload.message;
      game.bgGames = [];
    },
    demoBgGamesRequested: (game, action) => {
      game.demoBgGamesLoading = true;
    },
    demoBgGamesReceived: (game, action) => {
      game.demoBgGamesLoading = false;
      game.demoBgGames = action.payload.data;
    },
    demoBgGamesRequestFailed: (game, action) => {
      game.demoBgGamesLoading = false;
    },

    bgGamesDetailReceived: (game, action) => {
      game.bgGameDetails = action.payload;
    },

    //  Share Game Score
    shareGameScoreRequested: (game, action) => {
      game.shareScoreLoading = true;
    },
    shareGameScoreReceived: (game, action) => {
      game.shareScoreLoading = false;
      game.shareGameScoreDetails = action.payload;
    },
    shareGameScoreRequestFailed: (game, action) => {
      game.shareScoreLoading = false;
      game.error = action.payload.message;
      game.shareGameScoreDetails = null;
    },

    // initialGameDataRecieved
    initialGameDataRecieved: (game, action) => {
      game.gameData = action.payload.gameData;
    },

    enterGameRecieved: (game, action) => {
      console.log("enterGameRecieved", action.payload);
      game.transactionId = action.payload.data._id;
    },

    initialGameDataRequested: (game, action) => {
      game.gameScore = 0;
    },

    createGameSuccess: (game, action) => {
      game.gameId = action.payload.game;
    },
    createGameRequested: (game, action) => {
      game.lastGameCreatedAt = Date.now();
      game.gameScore = 0;
    },
    saveScoreRequested: (game, action) => {
      game.lastScoreSavedAt = Date.now();
    },
    saveFinalScoreReceived: (game, action) => {
      game.finalScore = action.payload;
      game.lastGameCreatedAt = null;
    },
    saveFinalScoreStatusReceived: (game, action) => {
      game.finalScoreResponseStatus = action.payload;
    },
    saveFinalScoreRequested: (game, action) => {
      game.lastFinalScoreSavedAt = Date.now();
      game.startTimer = false;
    },

    gameScoreRecieved: (game, action) => {
      game.gameScore = action.payload;
    },
    startTimer: (game, action) => {
      game.startTimer = true;
    },
  },
});
export const {
  prepareGameRequested,
  prepareGameReceived,
  prepareGameRequestFailed,
  gameQuestionRequested,
  gameQuestionReceived,
  gameQuestionRequestFailed,
  answerSubmitted,
  answerSubmittedSuccess,
  answerSubmittedFailed,
  gameResultRequested,
  gameResultReceived,
  gameResultRequestFailed,
  gameContestRequested,
  gameContestReceived,
  gameContestRequestFailed,
  removeOneRequested,
  removeOneReceived,
  removeOneRequestFailed,
  bgGamesRequested,
  bgGamesReceived,
  bgGamesRequestFailed,
  demoBgGamesRequested,
  demoBgGamesReceived,
  demoBgGamesRequestFailed,
  createGameSuccess,
  createGameRequested,
  shareGameScoreRequested,
  shareGameScoreReceived,
  shareGameScoreRequestFailed,
  saveScoreRequested,
  saveFinalScoreRequested,
  bgGamesDetailReceived,
  saveFinalScoreReceived,
  saveFinalScoreStatusReceived,
  initialGameDataRecieved,
  initialGameDataRequested,
  gameScoreRecieved,
  startTimer,
  cowitzerLaunched,
  cowitzerLaunching,
  boardGameLoaded,
  enterGameRecieved,
} = slice.actions;

export default slice.reducer;

// Action Creators
const gameUrl = "game/";

// Action Creators

// Action for requesting a game
export const prepareGame = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: gameUrl + "prePareGame",
      onStart: prepareGameRequested.type,
      onSuccess: prepareGameReceived.type,
      onError: prepareGameRequestFailed.type,
      params: data,
      callback,
    }),
  );
};

// Action for remove one
export const removeOne = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "removeOne",
      onStart: removeOneRequested.type,
      onSuccess: removeOneReceived.type,
      onError: removeOneRequestFailed.type,
      data: data,
      callback,
    }),
  );
};

// Action for contest details
export const getGameContest = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "contest/" + id,
      onStart: gameContestRequested.type,
      onSuccess: gameContestReceived.type,
      onError: gameContestRequestFailed.type,
      callback,
    }),
  );
};

// Action for BG Games
export const cowitzerEnter = (data, callback) => (dispatch, getState) => {
  // if got cowitzerLaunching then return
  if (getState().entities.game.cowitzerLaunching) return;
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "game/cowitzer/entercontest",
      data,
      onStart: cowitzerLaunching.type,
      onSuccess: cowitzerLaunched.type,
      onError: cowitzerLaunched.type,
      callback,
    }),
  );
};

// Action for BG Games
export const loadBgGames = (id, callback) => (dispatch, getState) => {
  // params.sort = "createdAt";
  // params.order = "desc ";
  const { lastFetchBgGames } = getState().entities.game;
  const diffInMinutes = moment().diff(moment(lastFetchBgGames), "minutes");
  if (diffInMinutes < 2) return;
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "metaBgGame/" + id,
      onStart: bgGamesRequested.type,
      onSuccess: bgGamesReceived.type,
      onError: bgGamesRequestFailed.type,
      callback,
    }),
  );
};
export const loadDemoGames = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "bgGame/demo",
      onStart: demoBgGamesRequested.type,
      onSuccess: demoBgGamesReceived.type,
      onError: demoBgGamesRequestFailed.type,
      callback,
    }),
  );
};

// Action for BG Games
export const loadBgGameDetail = (slug, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "bgGame/" + slug,
      onSuccess: bgGamesDetailReceived.type,
      callback,
    }),
  );
};

//  Action for sharing game score
export const shareGameScore = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: gameUrl + "shareGameScore/" + id,
      onStart: shareGameScoreRequested.type,
      onSuccess: shareGameScoreReceived.type,
      onError: shareGameScoreRequestFailed.type,
      callback,
    }),
  );
};

// Action for Game result
export const getGameResult = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "gameOver",
      onStart: gameResultRequested.type,
      onSuccess: gameResultReceived.type,
      onError: gameResultRequestFailed.type,
      data: data,
      callback,
    }),
  );
};

// Action for requesting a question
export const loadGameQuestion = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "getQuestion",
      onStart: gameQuestionRequested.type,
      onSuccess: gameQuestionReceived.type,
      onError: gameQuestionRequestFailed.type,
      data: data,
      callback,
    }),
  );
};

// Action for submitting an answer
export const submitAnswer = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: gameUrl + "sendAnswer",
      onStart: answerSubmitted.type,
      onSuccess: answerSubmittedSuccess.type,
      onError: answerSubmittedFailed.type,
      data: data,
      callback,
    }),
  );
};

export const initialGameData = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "metaBgGame/initialGameData",
      onSuccess: initialGameDataRecieved.type,
      onStart: initialGameDataRequested.type,
      data: data,
      callback,
    }),
  );
};

export const enterGame = (data) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "metaBgGame/enterGame",
      onSuccess: enterGameRecieved.type,
      data: data,
    }),
  );
};

// Function for create new game and send data in callback function
export const createNewGame = (callback) => (dispatch, getState) => {
  const { lastGameCreatedAt } = getState().entities.game;
  const diffInSeconds = moment().diff(moment(lastGameCreatedAt), "seconds");
  if (diffInSeconds < 10) return;
  const { transactionId } = getState().entities.boardGame;
  const { gameData } = getState().entities.boardGame;
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "metaBgGame/createGame",
      onStart: createGameRequested.type,
      onSuccess: createGameSuccess.type,
      data: { transactionId, gameData },
      callback,
    }),
  );
};

export const saveBreadcrumb = (data, callback) => (dispatch, getState) => {
  const { gameId } = getState().entities.boardGame;
  data.gameId = gameId;
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "metaBgGame/saveBreadcrumb",
      onStart: saveScoreRequested.type,
      data: data,
      callback,
    }),
  );
};

export const saveFinalScore = (data, callback) => (dispatch, getState) => {
  const { gameId, lastFinalScoreSavedAt } = getState().entities.boardGame;
  const diffInSeconds = moment().diff(moment(lastFinalScoreSavedAt), "seconds");
  if (diffInSeconds < 10) return;
  data.gameId = gameId;
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "metaBgGame/saveFinalScore",
      onStart: saveFinalScoreRequested.type,
      onSuccess: saveFinalScoreReceived.type,
      data: data,
      callback,
    }),
  );
};

// Board game
export const savePosition = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "POST",
      url: "boardGame/game/save-position",
      data: data,
      callback,
    }),
  );
};

// boardGame/game/get-available-dice
export const getAvailableDice = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/get-available-dice",
      callback,
    }),
  );
};

export const getposition = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/get-position",
      callback,
    }),
  );
};

//boardGame/game/roll-dice
export const rollDice = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/roll-dice",
      callback,
    }),
  );
};

//get-card
export const getCard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/get-card",
      callback,
    }),
  );
};

// get-user-card-list
export const getUserCardList = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/get-user-card-list",
      callback,
    }),
  );
};

//get-board
export const getBoard = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/get-board",
      callback,
    }),
  );
};
export const loadSpinRewardsList = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/list-spin-reward",
      callback,
    }),
  );
};
export const loadSpinReward = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "GET",
      url: "boardGame/game/spin",
      callback,
    }),
  );
};
export const saveScoreStatusRedux = (status) => (dispatch) => {
  dispatch(saveFinalScoreStatusReceived(status));
};

export const gameScoreUpdateRedux = (score) => (dispatch) => {
  dispatch(gameScoreRecieved(score));
};
export const startTimerRedux = () => (dispatch) => {
  dispatch(startTimer());
};

// Selector
export const getBoardGame = createSelector(
  (state) => state.entities.boardGame,
  (boardGame) => boardGame,
);
