import React, { useEffect, useState } from "react";
import { getUser, loadProfile, togglePopupRedux, toggleWalletPopupRedux, checkZRunNft } from "store/user";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import DiscordChatModal from "common/sections/discordChat";
import Header from "common/header";
import DetailsModalCard from "contests/components/common/detailsModalCard";
import { Accordion } from "react-bootstrap";
import mixpanel from "mixpanel-browser";
import HowItWorks from "contests/components/modals/howItWorks";
import {
  cowitzerEnter,
  getAvailableDice,
  getBoard,
  getCard,
  getGame,
  getposition,
  getUserCardList,
  loadBgGameDetail,
  loadCowitzerLeaderBoard,
  rollDice,
  savePosition,
} from "store/game";

import { getBoardGame, loadBgGames } from "store/boardGame";

import MetagameSpinner from "metagameBoosts/metagameSpinner";
import GameSectionBoard from "./boardGame/gameSections";
import WonRewards from "metagameBoosts/wonRewards";

const cardIcon1 = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/s-new-icon-1.png";
const musicIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/music-000.png";
const mutedIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/mute-icon-001.png";
const sounds = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/Sound.svg";
const musicMuted = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/Vector.svg";
const pageIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/page-icon-02.svg";
const logo = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/burnghostHorizontal.png";

window.MESSAGE_FACTORY = function (name, data) {
  console.log("MESSAGE_FACTORY", name, data);
  return JSON.stringify({
    type: name,
    data: data,
  });
};
const BoardGame = ({
  loadProfile,
  getUser,
  togglePopupRedux,
  toggleWalletPopupRedux,
  checkZRunNft,
  loadBgGames,
  loadBgGameDetail,
  cowitzerEnter,
  loadCowitzerLeaderBoard,
  getGame,
  savePosition,
  getposition,
  rollDice,
  getBoard,
  getAvailableDice,
  getUserCardList,
  getCard,
  getBoardGame,
  ...rest
}) => {
  window.GC_API = {
    UserBalance: "UserBalance",
    GameLoaded: "GameLoaded",
    GameStart: "GameStart",
    setIsWaitingForNextRound: "setIsWaitingForNextRound",
    setIsTimerRunning: "setIsTimerRunning",
    setMultiplierValue: "setMultiplierValue",
    PlaceBet: "PlaceBet",
    Multiplier: "Multiplier",
    NewBet: "NewBet",
    CashOut: "CashOut",
    Last10Rounds: "Last10Rounds",
    multiplierValue: "multiplierValue",
    startingIn: "startingIn",
    mybets: "mybets",
    winner: "winner",
    dogBet: "dogBet",
    gameState: "gameState",
    setIsMuted: "setIsMuted",
    setIsBackgroundMuted: "setIsBackgroundMuted",
    RESET: "RESET",
  };

  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [howItWork, setHowItWork] = useState({});
  const [showGame, setShowGame] = useState(false);
  const [bgGameLoaded, setBgGameLoaded] = useState(false);
  const [music, setMusic] = useState(localStorage.getItem("musicMuted") === "true");
  const [muted, setMuted] = useState(localStorage.getItem("soundMuted") === "true");
  const [openSpinner, setOpenSpinner] = useState(false);
  const [slotSpinner, setSlotsSpinner] = useState(false);
  const [showWonRewards, setShowWonRewards] = useState("");
  const [rewardValue, setRewardValue] = useState(0);
  const [randomGame, setRandomGame] = useState({});

  useEffect(() => {
    if (showGame) {
      console.log("getBoardGamegetBoardGame", getBoardGame.bgGames);

      // select a random game
      const randomGame = getBoardGame.bgGames;
      setRandomGame(randomGame);
    }
  }, [showGame]);

  const toggleMusic = (e) => {
    if (e) e.preventDefault();
    updateSound("music");
    setMusic(!music);
  };

  const toggleMute = (e) => {
    if (e) e.preventDefault();
    updateSound("sound");
    setMuted(!muted);
  };

  const updateSound = (type) => {
    const iFrame = document.getElementById("iframe-container-boardGame");
    if (!iFrame) return;

    if (type === "music") {
      const muteStateData = {
        musicMuted: !music,
        soundsMuted: muted,
      };
      // send MuteState message to iframe using MessageFactory
      iFrame.contentWindow.postMessage(window.MESSAGE_FACTORY("MuteState", muteStateData), "*");

      localStorage.setItem("musicMuted", muteStateData.musicMuted);
    }

    if (type === "sound") {
      const muteStateData = {
        musicMuted: music,
        soundsMuted: !muted,
      };
      // send MuteState message to iframe setIsBackgroundMuted
      iFrame.contentWindow.postMessage(window.MESSAGE_FACTORY("MuteState", muteStateData), "*");

      localStorage.setItem("soundMuted", muteStateData.soundsMuted);
    }
    iFrame?.focus();
  };
  const toggleHowItWorks = (e) => {
    if (e) e.preventDefault();
    setShowHowItWorks(!showHowItWorks);
  };
  useEffect(() => {
    loadProfile((res) => {
      if (res.status === 401) window.location.href = "/contests";
    });
    const slug = rest.router.params.slug;
    loadBgGameDetail(slug, (res) => {
      setHowItWork(res.data.data);
      setBgGameLoaded(res.data.data);
    });
  }, []);

  const rollDiceCall = () => {
    rollDice((res) => {
      getAvailableDiceCall();
      console.log("rollDiceCall", res);
      const iFrame = document.getElementById("iframe-container-boardGame");
      console.log("iFrame", iFrame);
      iFrame.contentWindow.postMessage(
        window.MESSAGE_FACTORY("getDiceRoll", {
          diceRoll: res.data,
        }),
        "*",
      );
    });
  };

  //getAvailableDiceCall
  const getAvailableDiceCall = () => {
    getAvailableDice((res) => {
      console.log("getAvailableDice", res);
      // seding message to iframe
      const iFrame = document.getElementById("iframe-container-boardGame");
      console.log("iFrame", iFrame);
      iFrame.contentWindow.postMessage(
        window.MESSAGE_FACTORY("getAvailableDice", {
          diceRoll: res.data.data,
        }),
        "*",
      );
    });
  };

  //get board
  const getBoardCall = () => {
    getBoard((res) => {
      console.log("getBoard", res);
      // seding message to iframe
      const iFrame = document.getElementById("iframe-container-boardGame");
      console.log("iFrame", iFrame);
      iFrame.contentWindow.postMessage(
        window.MESSAGE_FACTORY("getBoard", {
          getBoard: res.data.data,
        }),
        "*",
      );

      setTimeout(() => {
        getPositionCall();
      }, 1000);
    });
  };

  const getPositionCall = () => {
    getposition((res) => {
      console.log("getpositiongetposition", res.data.dailyReward);
      if (!res.data.dailyReward) setOpenSpinner(true);

      if (res.data?.data?.positionLog) {
        const iFrame = document.getElementById("iframe-container-boardGame");
        iFrame.contentWindow.postMessage(
          window.MESSAGE_FACTORY("getPosition", {
            position: res.data.data,
          }),
          "*",
        );
      }
    });
  };

  useEffect(() => {
    if (!bgGameLoaded) return;
    if (process.env.REACT_APP_MIXPANEL == 1) {
      mixpanel?.track("Cowitzer Viewed");
    }

    getposition((res) => {
      console.log("getpositiongetposition", res.data.dailyReward);
      if (!res.data.dailyReward) setOpenSpinner(true);

      if (res.data?.data?.positionLog) {
        const iFrame = document.getElementById("iframe-container-boardGame");
        iFrame.contentWindow.postMessage(
          window.MESSAGE_FACTORY("getPosition", {
            position: res.data.data,
          }),
          "*",
        );
      }
    });

    //Loading iframe
    const iFrame = document.getElementById("iframe-container-boardGame");

    //setTimeout(() => {
    iFrame.src = bgGameLoaded.url;
    //}, 1000);

    window.loading = false;
    window.launched = false;

    const savePositionCall = (position) => {
      console.log("savePosition", position);
      savePosition({ position: position }, (res) => {
        console.log("savePosition", res);
        if (res.data.icon.type === "slot") {
          setOpenSpinner(true);
          setSlotsSpinner(true);
        } else if (res.data.icon.type == "spinner") {
          setOpenSpinner(true);
        } else if (res.data.icon.type === "dice" || res.data.icon.type === "coin") {
          setShowWonRewards(res.data.icon.type);
          setRewardValue(res.data.icon.prizeValue);
        } else if (res.data.boardMyType.bgGameId) {
          loadBgGames(res.data.boardMyType.bgGameId, (res) => {
            console.log(res);
            setShowGame(true);
          });
        }
      });
    };

    const getCardCall = () => {
      getCard((res) => {
        console.log("getCardCall", res);
        // seding message to iframe
        const iFrame = document.getElementById("iframe-container-boardGame");
        console.log("iFrame", iFrame);
        iFrame.contentWindow.postMessage(
          window.MESSAGE_FACTORY("getCartRecieved", {
            data: res.data.data,
          }),
          "*",
        );
      });
    };

    const getUserCardListCall = () => {
      getUserCardList((res) => {
        console.log("getUserCardList", res);
        // seding message to iframe
        const iFrame = document.getElementById("iframe-container-boardGame");
        console.log("iFrame", iFrame);
        iFrame.contentWindow.postMessage(
          window.MESSAGE_FACTORY("getUserCartListRecieved", {
            diceRoll: res.data.data,
          }),
          "*",
        );
      });
    };

    const listener = (event) => {
      // Your existing event listener logic
      try {
        if (typeof event.data !== "string") return;
        const ourMessage = JSON.parse(event.data);
        console.log("111ourMessageourMessage===>", ourMessage);
        if (ourMessage?.type) {
          switch (ourMessage.type) {
            case "Position":
              savePositionCall(ourMessage.data);
              break;
            case "sendDiceRoll":
              console.log("sendDiceRoll");
              rollDiceCall();
              break;
            case "gameLoaded":
              getAvailableDiceCall();
              getPositionCall();
              getUserCardListCall();
              getBoardCall();
              break;
            case "getCard":
              getCardCall();
              break;
            case "getUserCardList":
              getUserCardListCall();
              break;
            default:

            // Handle other events if needed
          }
        }
      } catch (e) {
        // Ignore exception - not a message for us and couldn't JSON parse it
        console.log(e);
      }
    };

    window.addEventListener("message", listener);
  }, [bgGameLoaded]);

  useEffect(() => {
    if (!showGame && bgGameLoaded) {
      //Loading iframe
      const iFrame = document.getElementById("iframe-container-boardGame");
      if (!iFrame) return;
      iFrame.src = bgGameLoaded.url;
    }
  }, [showGame]);

  useEffect(() => {
    if (!getUser.profile.userProfile.lootBonusCoins) return;
    const iFrame = document.getElementById("iframe-container-boardGame");
    iFrame.contentWindow.postMessage(
      window.MESSAGE_FACTORY("userProfile", {
        lootBonusCoins: getUser.profile.userProfile,
      }),
      "*",
    );
  }, [getUser.profile.userProfile]);

  console.log("Position:", openSpinner, slotSpinner);
  // User Profile data
  return (
    <>
      <div className="fs-game-page-wrapper dark-bg min-vh-100">
        <div className="container-fluid px-lg-2 px-2">
          <div className="row gx-2">
            <div className="col-md-12">
              <div className="fs-game-page-holder vh-100 d-flex flex-lg-row flex-column flex-lg-nowrap">
                <div className="fs-game-page-main h-100 flex-grow-1 d-flex flex-column">
                  {localStorage.getItem("appLogin") !== "true" && (
                    <div className="lg-none-header">
                      <Header crashGame={false} themeMode={"dark"} />
                    </div>
                  )}
                  {showGame ? (
                    <div className="position-relative h-100 iframe-game">
                      <GameSectionBoard randomGame={randomGame} setShowGame={setShowGame} />
                    </div>
                  ) : (
                    <div className="position-relative h-100">
                      <iframe
                        id="iframe-container-boardGame"
                        className="iframe-container position-absolute"
                        height="100%"
                        width="100%"
                        title="boardGame"
                        src="https://dev-games.burnghost.com/meta-game/board/"
                      ></iframe>
                      {openSpinner && (
                        <MetagameSpinner
                          getAvailableDiceCall={getAvailableDiceCall}
                          toggle={slotSpinner}
                          handleClose={() => {
                            setSlotsSpinner(false);
                            setOpenSpinner(false);
                          }}
                        />
                      )}
                      {showWonRewards && (
                        <WonRewards
                          handleClose={() => {
                            setShowWonRewards(false);
                            setRewardValue(0);
                            setShowWonRewards("");
                          }}
                          rewardType={showWonRewards}
                          rewardValue={rewardValue}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorks showModal={showHowItWorks} toggleModal={toggleHowItWorks} bgGame={howItWork} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadProfile: (callback) => dispatch(loadProfile(callback)),
  togglePopupRedux: (payload) => dispatch(togglePopupRedux(payload)),
  toggleWalletPopupRedux: (data) => dispatch(toggleWalletPopupRedux(data)),
  checkZRunNft: (data) => dispatch(checkZRunNft(data)),
  loadBgGames: (data, callback) => dispatch(loadBgGames(data, callback)),
  loadBgGameDetail: (data, callback) => dispatch(loadBgGameDetail(data, callback)),
  cowitzerEnter: (data, callback) => dispatch(cowitzerEnter(data, callback)),
  loadCowitzerLeaderBoard: () => dispatch(loadCowitzerLeaderBoard()),
  getAvailableDice: (callback) => dispatch(getAvailableDice(callback)),
  savePosition: (data, callback) => dispatch(savePosition(data, callback)),
  getposition: (callback) => dispatch(getposition(callback)),
  rollDice: (callback) => dispatch(rollDice(callback)),
  getBoard: (callback) => dispatch(getBoard(callback)),
  getUserCardList: (callback) => dispatch(getUserCardList(callback)),
  getCard: (callback) => dispatch(getCard(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
  getGame: getGame(state),
  getBoardGame: getBoardGame(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardGame));
