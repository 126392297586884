import Carousel from "./carousel";
import React, { useState, useEffect } from "react";
import Spinner from "./spinner";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { getGame, loadSpinReward, loadSpinRewardsList, loadSlotRewardsList, loadSloReward } from "store/game";
import Skeleton from "react-loading-skeleton";
import LoaderImg from "common/loaderImag";
import ConfettiJSON from "../include/confetti.json";
import Lottie from "react-lottie-player";
import { createRef } from "react";

const lottieRef = createRef(null);
const successIcon = process.env.REACT_APP_WEBSITE_ASSETS_URL + "images/winner-tropy-01.png";

const WonRewards = ({ handleClose, rewardType, rewardValue }) => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowConfetti(false);
    }, 3500);
  }, []);
  return (
    <div className="modal-div position-absolute top-50 start-50 translate-middle">
      <div className="custom-row-body">
        <div className="custom-number-area text-center text-white mt-0">
          <div className="cn-title">
            {rewardValue} {rewardType}
          </div>
          <div className="ct-text-box">
            <p>
              You won {rewardValue} {rewardType} that you can use in the metagame
            </p>
          </div>
          <div className="cn-img-box">
            <img src={successIcon} alt="trophy" />
          </div>
        </div>
        {showConfetti && (
          <div className={`lottie-player-fixed`} style={{ zIndex: 999992 }}>
            <Lottie
              background="transparent"
              speed="0.9"
              loop={false}
              play
              ref={lottieRef}
              animationData={ConfettiJSON}
            />
          </div>
        )}
      </div>
      <button className={"spinButton"} onClick={handleClose}>
        Close
      </button>
    </div>
  );
};
export default WonRewards;
