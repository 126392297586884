import { combineReducers } from "redux";
import userReducer from "./user";
import contestReducer from "./contest";
import gameReducer from "./game";
import boardGameReducer from "./boardGame";
import practiceGameReducer from "./practiceGame";
import sweepstakes from "./sweepstakes";
import lootbox from "./lootbox";

export default combineReducers({
  user: userReducer,
  contest: contestReducer,
  game: gameReducer,
  boardGame: boardGameReducer,
  practiceGame: practiceGameReducer,
  sweepstakes,
  lootbox,
});
