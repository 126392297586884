import React, { useEffect, useRef } from "react";
import IframeComponent from "./iframeComponent";
import {
  createNewGame,
  enterGame,
  gameScoreUpdateRedux,
  getBoardGame,
  initialGameData,
  saveBreadcrumb,
  saveFinalScore,
  saveScoreStatusRedux,
  startTimerRedux,
} from "store/boardGame";
import { getUser } from "store/user";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";

const GameSectionBoard = (props) => {
  const [error, setError] = React.useState(false);
  useEffect(() => {
    if (error) {
      //setTab("game");
      console.log("error", error);
    }
  }, [error]);

  const iframeRef = useRef(null);

  const creatingGame = () => {
    props.startTimer();
    setTimeout(() => {
      props.createNewGame();
    }, 2000);
  };

  const restartGame = () => {
    props.contestEntryCall();
  };

  const goHome = () => {
    props.router.navigate("/");
  };

  // Function to save breadcrumb
  const savingBreadcrumb = (message) => {
    const payLoad = {
      data: message,
    };

    // API call to save breadcrumb
    props.saveBreadcrumb(payLoad, async (res) => {
      // Sleep for 500ms to allow the iframe to load the next page
      await Promise.resolve(setTimeout(() => {}, 500));
      processQueue();
      if (res.status !== 200)
        return props.setError(
          "An unknown error has occurred. Please try again. If the problem continues, kindly get in touch with our support team.",
        );
      // start the queue processing
    });
  };

  const gameScoreUpdating = (message) => {
    props.gameScoreUpdateRedux(message);
  };

  const saveFinalScore = (message) => {
    const payLoad = {
      data: message,
    };

    // API call to save final score
    props.saveFinalScore(payLoad, (res) => {
      if (res.status === 409 || res.status === 200) {
        const gameEndDelay = res.data.gameEndDelay ? res.data.gameEndDelay : 0;
        setTimeout(() => {
          props.setShowGame(false);
        }, gameEndDelay);

        processQueue();
      } else
        return props.setError(
          "An unknown error has occurred. Please try again. If the problem continues, kindly get in touch with our support team.",
        );
    });
  };
  var apiMessageQueueApiCall = { data: [] };

  // Function to push incoming messages to the queue
  const pushToQueue = (data, type) => {
    console.log("pushToQueue===?", data, type);
    apiMessageQueueApiCall.data.push({ data, type });
  };

  const processQueue = () => {
    if (apiMessageQueueApiCall.data.length === 0) {
      // Queue is empty, wait for 500ms and then check again
      return setTimeout(processQueue, 500);
    }

    const message = apiMessageQueueApiCall.data[0]; // Get the first message from the queue

    // Remove the first message from the queue
    apiMessageQueueApiCall.data = apiMessageQueueApiCall.data.slice(1);

    switch (message.type) {
      case "BreadCrumb":
        savingBreadcrumb(message.data);
        break;
      case "FinalScores":
        saveFinalScore(message.data);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Clear cache on page load
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
    // Set up the event listener
    window.GC_API = {
      GameLoaded: "GameLoaded",
      BreadCrumb: "BreadCrumb",
      FinalScores: "FinalScores", //sends a bunch of final data
      NewGame: "NewGame",
      GoHome: "GoHome",
      MuteState: "MuteState",
      Score: "Score",
      MuteMusic: "MuteMusic",
      MuteSounds: "MuteSounds",
      GameStart: "GameStart",
    };

    window.CG_API = {
      InitGame: "InitGame",
      MuteState: "MuteState",
    };

    window.MESSAGE_FACTORY = function (name, data) {
      return JSON.stringify({
        type: name,
        data: data,
      });
    };
    // The queue to store incoming API messages

    var eventListenerGame = function (event) {
      try {
        const message = JSON.parse(event.data);
        if (message?.type) {
          switch (message.type) {
            case "Score":
              gameScoreUpdating(message.data.totalScore);
              break;

            case "GameLoaded":
              initialGameDataCall();
              break;

            case "GameStart":
              creatingGame();
              break;

            case "BreadCrumb":
              console.log("BreadCrumb message.data", message.data);
              // merge game id with message data
              //savingBreadcrumb(message.data);
              pushToQueue(message.data, "BreadCrumb");
              break;

            case "FinalScores":
              console.log("FinalScores message.data", message.data);
              //saveFinalScore(message.data);
              pushToQueue(message.data, "FinalScores");
              break;

            default:
              break;
          }
        }
      } catch (e) {
        // Ignore exception - not a message for us and couldn't JSON parse it
      }
    };
    // Add event listener for messages from the iframe

    window.addEventListener("message", eventListenerGame);

    const queueTimer = processQueue();

    return () => {
      clearTimeout(queueTimer); // Clear the timeout to stop the processQueue function
      // Clean up the event listener when the component unmounts
      window.removeEventListener("message", eventListenerGame);
    };
  }, []);

  const initialGameDataCall = () => {
    //alert("initialGameDataCall");
    console.log("initialGameDataCall randomGame", props.getBoardGame.bgGames);
    const iFrame = document.getElementById("iframe-container");
    const muteStateData = {
      musicMuted: localStorage.getItem("musicMuted") === "true" ? true : false,
      soundsMuted: localStorage.getItem("soundMuted") === "true" ? true : false,
    };
    // send MuteState message to iframe
    const mutedMessage = window.MESSAGE_FACTORY(window.CG_API.MuteState, muteStateData);

    iFrame.contentWindow.postMessage(mutedMessage, "*");
    props.enterGame({ bgGameId: props.getBoardGame.bgGames.id });
    props.initialGameData({ bgGameId: props.getBoardGame.bgGames.id }, (res) => {
      const message = window.MESSAGE_FACTORY(window.CG_API.InitGame, res.data.gameData);
      iFrame.contentWindow.postMessage(message, "*");

      const muteStateData = {
        musicMuted: localStorage.getItem("musicMuted") === "true" ? true : false,
        soundsMuted: localStorage.getItem("soundMuted") === "true" ? true : false,
      };
      // send MuteState message to iframe
      const mutedMessage = window.MESSAGE_FACTORY(window.CG_API.MuteState, muteStateData);

      iFrame.contentWindow.postMessage(mutedMessage, "*");
    });
  };
  console.log("props.randomGame", props.randomGame);

  return <IframeComponent gameUrl={props.randomGame.url} />;
};

const mapDispatchToProps = (dispatch) => ({
  createNewGame: (data, callback) => dispatch(createNewGame(data, callback)),
  enterGame: (data) => dispatch(enterGame(data)),
  saveBreadcrumb: (data, callback) => dispatch(saveBreadcrumb(data, callback)),
  saveFinalScore: (data, callback) => dispatch(saveFinalScore(data, callback)),
  saveScoreStatusRedux: (payLoad) => dispatch(saveScoreStatusRedux(payLoad)),
  gameScoreUpdateRedux: (payLoad) => dispatch(gameScoreUpdateRedux(payLoad)),
  initialGameData: (data, callback) => dispatch(initialGameData(data, callback)),
  startTimer: () => dispatch(startTimerRedux()),
});
const mapStateToProps = (state) => ({
  getBoardGame: getBoardGame(state),
  getUser: getUser(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GameSectionBoard));
